import React, {useState} from "react";
import {useQuery} from "@apollo/client";
import {CLINIC_SEARCH, SEARCH_BY_ID} from "../../../../../graphql/queries/clinic-search";
import ClinicSearch from "../../../../ClinicSearch";

const SearchPreviewPane = ({id, terms}) => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(0)

  const {data: {search: {entries}} = {search: {entries: []}}, loading} = useQuery(CLINIC_SEARCH, {
    variables: {
      page,
      limit: pageSize,
      terms,
    },
    onCompleted: ({ search: { pageInfo, totalCount: tc } }) => {
      if (pageInfo) {
        setTotalCount(tc)
      } else {
        setTotalCount(0)
      }
    },
  })

  return <>
    <ClinicSearch
      showLink={false}
      entries={entries}
      loading={loading}
      onPageChange={setPage}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      totalCount={totalCount}
      page={page}
    />
  </>
}

export default SearchPreviewPane
