
export const TREATMENT_CATEGORY = [
  {
    id: "a74cc523-67fc-4878-a6e5-2f7220f05196", name: "Health and Beauty", treatment_terms: [
      "Beauty Salon", "Beauty Boutique", "Manicure", "Pedicure", "Eyelash & eyebrow", "Spray Tans", "Tanning", "Sun Bed",
    ],
    market_classification: "c7b42e5-1849-41a8-b24d-7ecb63712ac9",
  },
  {
    id: "96bd4f42-a3d5-40a4-a75e-2ba5fe2e8d52", name: "Residential Care", treatment_terms: [
      "care home", "care homes", "Residential care", "Nursing Home", "Specialist residential services", "Supported Living",
    ],
    market_classification: "6d79548-6cdd-4cb8-9a72-329ee37d9fa6",
  },
  {
    id: "7c767920-2b0d-431a-a83b-92d434841ade", name: "Dental Cosmetics", treatment_terms: [
      "cosmetic dentistry", "cosmetic dentist", "Non-cutting veneers", "ceramic and cerec crowns", "dental implants", "zirconia implants", "gum contour", "Lingual Braces", "Labial Braces", "Ceramic Braces", "Damon Advanced Bracket Systems", "Invisalign", "Periodontist", "Clear aligners"
    ],
    market_classification: "650b767-31f4-4739-b4bc-1f15a8e066e9",
  },
  {
    id: "112d3335-db95-43e5-88cf-6d527dcf03f7", name: "Dental", treatment_terms: [
      "root canal", "dentures", "dental implants", "emergency dental care", "Orthodontics", "Dental Exam", "Dental Hygiene", "Root Canal", "Perio treatment", "Waterlase laser", "Gum Disease", "white fillings", "teeth whitening", "Tooth whitening"
    ],
    market_classification: "3b2dce6-478a-41f9-9323-8daee4df8a69",
  },
  {
    id: "9b5d2180-9446-4eba-a609-f88126f01455", name: "General Aesthetics", treatment_terms: [
      "facial aesthetics"
    ],
    market_classification: "ac8e832-c40c-4190-963d-c81c12e08823",
  },
  {id: "f984d8cb-bc95-45a7-8d6b-a040a5b9de56", name: "General Surgery", treatment_terms: ["cryotherapy",], market_classification: "94f1b21-8355-40c3-8155-c4f14eb4b7a2"},
  {
    id: "afbeb1de-9f4c-4f2e-aa93-fecefe5ed346",
    name: "NHS Services",
    treatment_terms: ["Community health services", "NHS foundation trust", "Patient Transport Services", "Patient Advice and Liaison Service", "NHS GP Video Consultations", "Livi App", "Electronic prescription service", "Repeat prescriptions", "Emergency Department", "Accident & Emergency", "paediatric surgery", "cardiology", "neurosurgery", "ENT", "Ear Nose and Throat", "Urgent Treatment Centre", "Major Trauma Centre", "Cancer Services", "Cardiac Surgery", "Gastroenterology", "Maternity Unit", "outpatient clinic", "Urology Department", "Occupational therapy",],
    market_classification: "3399247-dc20-4838-b5f5-0e65dd3e990e",
  },
  {
    id: "76efe6c9-ce76-461a-8ced-832603475a23", name: "Personal Care", treatment_terms: [
      "autism", "stress management", "alcohol treatment", "drugs treatment", "learning disability",
    ],
    market_classification: "64f1b8e-db1f-429d-aade-53c492fea5cc",
  },
  {
    id: "24939362-0e05-4a90-8338-6aad04336cb9", name: "Bio Stimulator", treatment_terms: [
      "Bio Stimulator", "Collagen Stimulator",
    ],
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
  },
  {
    id: "a3f76476-869e-4cd1-a24a-b99a672ece28", name: "Body Contouring", treatment_terms: [
      "Body sculpting", "Mesotherapy", "body contouring", "cellulite reduction",
    ],
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
  },
  {
    id: "384428e9-b8dd-49cd-b51c-64a324ab6662", name: "Botulinum Toxin", treatment_terms: [
      "Botox", "Botox®", "crows feet", "wrinkle reduction", "jawline muscle reduction", "face slimming", "jawline slimming", "sagging eyelids", "brotox", "toxin treatment", "neuromodulator", "botulinum toxin", "Wrinkle reduction", "Wrinkle correction", "BTX-A", "muscle relaxing injections", "injectable wrinkle smoothening injections", "injectable wrinkle injections", "Botulinum", "Botulinum Type A", "Frown lines", "wrinkle relaxation injections", "Wrinkle relaxing injection", "Wrinkle reducing injections", "Wrinkle-reducing", "anti-wrinkle", "crow’s feet", "anti-wrinkle injections", "Baby Botox", "antiwrinkle injections", "wrinkle relaxation injections", "Wrinkle reducing injections", "muscle relaxing injections", "Anti-ageing injections", "injectable wrinkle injections", "anti wrinkle", "Botulinium toxin", "anti-aging", "anti-ageing", "anti-aging injectables", "anti-ageing injectables", "anti aging", "anti ageing", "anti aging injectables", "anti ageing injectables", "Azzalure",
    ],
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
  },
  {
    id: "12fe1976-55d5-4f48-819f-6d56c372293f", name: "Carboxytherapy", treatment_terms: [
      "cellulite treatment", "stretch mark treatment", "under eye circle treatment", "fat loss treatment", "hair loss treatment",
    ],
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
  },
  {
    id: "556468d2-5c69-4315-8b53-70296f80d104", name: "Cryotherapy", treatment_terms: [
      "Coolsculpting", "aqualift", "fat freezing", "fat freeze", "Cryolipolysis",
    ],
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
  },
  {
    id: "ed594c40-6240-4e22-a8f2-697852d53fb0", name: "Dermal Fillers", treatment_terms: [
      "Lip Filler", "lip fillers", "Lip augmentation", "Dermal Filler", "Dermal Fillers", "facial scars", "jawline contouring", "mini facelift", "non-surgical face lift", "non-surgical face-lift", "facial rejuvenation", "Plumping of lips", "Lip enhancement", "Smoothing of wrinkles", "injectable fillers",
    ],
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
  },
  {
    id: "a8cbb779-88bc-410e-b17c-d8a0dfc4152c", name: "Fat Dissolving", treatment_terms: [
      "Non-surgical fat reduction", "double chin treatment", "coolsculpting", "fat dissolving injections", "double chin",
    ],
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
  },
  {
    id: "3de6d769-687c-47dc-9d04-943f0bf5c104", name: "Lasers LED & IPL", treatment_terms: [
      "laser hair removal", "IPL", "hair removal", "Low Level Laser Therapy", "LLLT for Hair Loss", "LLT Hair Loss", "Laser Hair Treatment", "Laser Hair Therapy",
    ],
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
  },
  {
    id: "ab2c1849-9ed0-47b7-b78c-b3d61f941391", name: "Non Surgical skin tightening", treatment_terms: [
      "ultherapy", "endolift", "hyfu", "morpheus", "thermage",
    ],
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
  },
  {
    id: "e8d4d657-d7ce-47f0-b4fc-9a789121e8a0", name: "Other Aesthetics", treatment_terms: [
      "intravenous vitamins", "b12 injection",
    ],
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
  },
  {
    id: "9c7e556b-ac23-4d20-bdc6-2315ff5c547f", name: "PRP Therapy", treatment_terms: [
      "Vampire Facial", "Vampire Facelift", "Platelet Rich Plasma", "PRP injection", "PRP injections", "PRP Hair Treatment", "Face & Neck PRP", "Face PRP", "Neck PRP", "PRP Treatment", "Platelet-Rich Plasma", "PRP for hair loss",
    ],
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
  },
  {
    id: "07f25504-fc7d-4642-b0d6-ca50e27ada21", name: "Skin Boosters", treatment_terms: [
      "Skinboosters", "Skin Boosters", "skin-boosters", "skinbooster", "skin booster", "skin-booster", "injectable moisturer", "hyaluronic acid moisturising treatment", "moisturising injection", "injectable moisturisers", "skin revitaliser", "injectable tissue stimulator", "tissue booster", "skin-quality injectables", "injectable skin hydration", "skin renewal and bio-restructuring", "injectable collagen stimulator", "injectable tissue stimulators", "injectable collagen stimulators",
    ],
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
  },
  {
    id: "8111f2ad-0511-44dc-ba56-da99d667b931", name: "Skin Treatments", treatment_terms: [
      "chemical peels", "Microdermabrasion", "dermabrasion", "medical grade skin care", "medical grade skin cream",
    ],
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
  },
  {
    id: "b3d10776-e654-499c-a962-dcf25fe9f83c", name: "Thread Lifts", treatment_terms: [
      "fox eyes", "brow lift", "jawline lift", "fox-eye lift", "fox eye thread lift", "fox-eye thread lift", "thread lift", "non-surgical thread lift", "Skin tightening", "GF needling",
    ],
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
  },
  {
    id: "2043d2aa-8552-4ca4-8cda-23f9a745be09", name: "Surgical Treatments", treatment_terms: [
      "nose job", "liposuction", "tummy tuck", "Breast augmentation", "breast enlargement", "silicone breast implants", "silicone implants", "breast implant",
    ],
    market_classification: "6e7a2ea-585e-405a-810a-0ad845d2fd99",
  },
  {
    id: "0b2abec5-5820-41fb-b9ee-bbb321b342d3", name: "Medicated Weight Management", treatment_terms: [
      "medicated weight management", "saxenda", "ozempic", "alli weight loss", "weight loss injection", "Weight Loss Injections", "Skinny Jab", "Fat injections", "Fat injection",
    ],
    market_classification: "ce6c504-b586-458a-9353-2175f1659b92",
  },
  {
    id: "4734156d-a18f-4023-8217-bd071f898cdf", name: "Unmedicated Weight management", treatment_terms: [
      "skinny pen", "skinny jab",
    ],
    market_classification: "ce6c504-b586-458a-9353-2175f1659b92",
  },
]

export const MARKET_CLASSIFICATION = [
  {id:"c7b42e5-1849-41a8-b24d-7ecb63712ac9", name: "Beauty Salon"},
  {id:"6d79548-6cdd-4cb8-9a72-329ee37d9fa6", name: "Care Homes"},
  {id:"650b767-31f4-4739-b4bc-1f15a8e066e9", name: "Cosmetic Dentist"},
  {id:"3b2dce6-478a-41f9-9323-8daee4df8a69", name: "Dentist"},
  {id:"ac8e832-c40c-4190-963d-c81c12e08823", name: "General Aesthetics"},
  {id:"94f1b21-8355-40c3-8155-c4f14eb4b7a2", name: "General Surgery"},
  {id:"3399247-dc20-4838-b5f5-0e65dd3e990e", name: "Hospital/GP/Surgery"},
  {id:"64f1b8e-db1f-429d-aade-53c492fea5cc", name: "Mental Health Services"},
  {id:"a615611-6863-4fb7-903f-94c8c11f0652", name: "Non-Surgical Aesthetics"},
  {id:"6e7a2ea-585e-405a-810a-0ad845d2fd99", name: "Surgical Aesthetics"},
  {id:"ce6c504-b586-458a-9353-2175f1659b92", name: "Weight Management"},
  // {id:"46d00088-e13b-4a3e-9a99-87a0eb6c1d2c", name: ""},
]

export const PRODUCT = [
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "3D-lipo Ltd",
    brand_name: ["3D Ultimate"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Allergan",
    brand_name: ["CoolTone"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Alma Laser",
    brand_name: ["Accent Prime"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "BTL Aesthetics",
    brand_name: ["EMSculpt", "Exilis Elite", "Vanquish"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Cool Touch",
    brand_name: ["CoolLipo"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Cutera",
    brand_name: ["TruSculpt"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Cynosure",
    brand_name: ["SculpSure"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Cynosure",
    brand_name: ["Smartlipo® Triplex"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "DEKA",
    brand_name: ["Laserlipolisi", "SmartLipo Dekalaser"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Elite Body Sculpture",
    brand_name: ["Airsculpt"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Eudelo",
    brand_name: ["The Eudelo Body"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "InMode",
    brand_name: ["Morpheus8", "Evolve Tone"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Invasix",
    brand_name: ["BodyTite"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Korean Daeyang Medical Co.",
    brand_name: ["CMSlim"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Osyris",
    brand_name: ["LipoTherm", "LipoControl"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Pollogen",
    brand_name: ["Lipofirm Pro"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Sciton",
    brand_name: ["ProLipo Plus"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Skra Medical",
    brand_name: ["TESLA Former"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Solta Medical",
    brand_name: ["Thermage CPT"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Syneron",
    brand_name: ["LipoLite"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Ultra Sculpt Ltd",
    brand_name: ["UltraSculpt"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "UNKNOWN",
    brand_name: ["3D Lipo"]
  },
  {
    treatment_category: "Body Contouring",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Venus Concept UK",
    brand_name: ["Venus Concept"]
  },
  {
    treatment_category: "Botulinum Toxin",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Allergan",
    brand_name: ["Vistabe", "Botox®, Botox"]
  },
  {
    treatment_category: "Botulinum Toxin",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Galderma",
    brand_name: ["Azzalur", "Alluzienc", "Dysport,"]
  },
  {
    treatment_category: "Botulinum Toxin",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Medytox",
    brand_name: ["Meditoxi", "Neurono", "Innoto", "Caretox"]
  },
  {
    treatment_category: "Botulinum Toxin",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Merz",
    brand_name: ["Bocoutur", "Xeomin"]
  },
  {
    treatment_category: "Botulinum Toxin",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Sloan Pharma",
    brand_name: ["Neurobloc"]
  },
  {
    treatment_category: "Cryotherapy",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Allergan",
    brand_name: ["Coolsculpting"]
  },
  {
    treatment_category: "Cryotherapy",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Cocoon Medical",
    brand_name: ["CoolTech"]
  },
  {
    treatment_category: "Cryotherapy",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Deleo",
    brand_name: ["Cristal"]
  },
  {
    treatment_category: "Cryotherapy",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Zimmer Aesthetics",
    brand_name: ["ZLipo"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "AESRHEA LABORATORIES",
    brand_name: ["Jealifill Dee", "Jealifill Ultra Deep"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "AlfaMedical",
    brand_name: ["Aqufill Har", "Aqufill Hydr", "Aqufill Mediu", "Aqufill Soft"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Allergan",
    brand_name: ["Juvederm Ultra 2, Juvederm Ultra 3, Juvederm Ultra 4, Juvederm Ultra Smil", "Juvederm Volbella , Juvederm Volif", "Juvederm Volift Retouc", "Juvederm Volum", "Juvederm Volux"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Allergan/Abbvie/Luminera",
    brand_name: ["Luminera Hydryalix Crystaly", "Luminera Hydryalix Dee", "Luminera Hydryalix Gentl", "Luminera Hydryalix Lip", "Luminera Hydryalix Ultra Dee", "Luminera Hydryal 2%, Luminera Hydryal 3%, Luminera Hydryal 4%"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Beautiful Korea Co.",
    brand_name: ["Ammi Aqu", "Ammi Crysta", "Ammi Hydro"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "belderma",
    brand_name: ["Dermalstyle Fort", "Dermalstyle Regula", "Dermalstyle Smile"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "BELLUS Health Inc",
    brand_name: ["Beautiful Us BellUs"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "BioPlus Co.",
    brand_name: ["DeneB Classic ", "DeneB Classic S"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "BioScience",
    brand_name: ["Genefill Contou", "Genefill D", "Genefill Fin", "Genefill Soft Fil", "Genefill Soft Touc", "HYAcorp Body Contouring MLF1, HYAcorp Body Contouring MLF2, HYAcorp Fac", "HYAcorp Fin", "HYAcorp Lips"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Bioxis pharmaceuticals",
    brand_name: ["Cytosial Deep Wrinkl", "Cytosial Mediu", "Cytosial Volume"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Bloomage Biotechnology Corp",
    brand_name: ["Bio Hyalux Deep Dermi", "Bio Hyalux Fine Line", "Bio Hyalux Lips"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "BNC Company Co.",
    brand_name: ["Cutegel ", "Cutegel ", "Cutegel Ma", "Cutegel Stron", "Cutegel Ultra"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Bohus BioTech AB",
    brand_name: ["Decoria Essenc", "Decoria Intens", "Decoria Volume"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "BR Pharm",
    brand_name: ["Revitrane Classi", "Revitrane HA 20 Skin Booste", "Revitrane Premiu", "Revitrane Volume"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "BTCSEVEN Co. Ltd.",
    brand_name: ["Secret Rose Plus Dee", "Secret Rose Plus Fin", "Secret Rose Plus Implan", "Secret Rose Plus Shape"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Cambridge Biotech",
    brand_name: ["Cambderm Classic"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Caregen",
    brand_name: ["CG styler Styler 600"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Caregen Nordic",
    brand_name: ["REVOFIL Aquashine B", "REVOFIL Aquashin", "REVOFIL Aquashine PT", "REVOFIL Fin", "REVOFIL Plu", "REVOFIL Ultr", "Prostrolane Natural ", "Prostrolane Blanc ", "Prostrolane Inner ", "Prostrolane Inner B SE"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Caregen Nordic",
    brand_name: ["Revofil Fin", "Revofil Plu", "Revofil Ultra"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "CHAMeditech",
    brand_name: ["Hyafilia ", "Hyafilia ", "Hyafilia ", "Hyafilia Classi", "Hyafilia Gran", "Hyafilia Petit"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "CM Aesthetics Ltd",
    brand_name: ["Medifeel Paris"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Contura Pharma",
    brand_name: ["Aquami", "Aquamid Reconstruction"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Croma-Pharma GmbH",
    brand_name: ["Saypha Fille", "Saypha Ric", "Saypha Volume"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Croma-Pharma Saypha",
    brand_name: ["Princess Fille", "Princess Volum", "Princess Rich"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Daejong meditec Co. Ltd",
    brand_name: ["LuxFill Plus Dee", "LuxFill Plus Fin", "LuxFill Plus Sub Q"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Daewoong Group",
    brand_name: ["Facetem Natural Face Natural System"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Dives",
    brand_name: ["Dives Fines Line", "Dives Lip", "Dives Polifill Stron", "Dives Universa", "Dives Volume"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Eleganmi",
    brand_name: ["Corefill Plus Sub-", "Corefill Plus Dee", "Corefill Plus Fine"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Fox Group International / Fox Pharma",
    brand_name: ["Revolax"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Galderma",
    brand_name: ["Restylane Lidocain", "Restylane Defyn", "Restylane Fyness", "Restylane Kyss", "Restylane Lyf", "Restylane Lyp", "Restylane Refyn", "Restylane Volym", "Sculptr", "Restylane Eyeline"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Genoss",
    brand_name: ["Monalisa Hard Typ", "Monalisa Mild Typ", "Monalisa Soft Type"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Godgel",
    brand_name: ["Godgel Dee", "Godgel Der", "Godgel Derm Sub-Ski", "Godgel Finelines"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Hangzhou Singclean Medical Products Co.,Ltd.",
    brand_name: ["Singder", "Singfiller Dee", "Singfiller Deepe", "Singfiller Der", "Singfiller Fine"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Hebei Weitamei Technology Co., Ltd.",
    brand_name: ["Misfill+ Dee", "Misfill+ Ligh", "Misfill+ Volume In"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "IBSA",
    brand_name: ["Viscoderm Trio"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "IBSA Nordic",
    brand_name: ["Aliaxin EV Essential Volum", "Aliaxin FL Firm Lip", "Aliaxin GP Global Performanc", "Aliaxin LV lips Volume , Aliaxin SR Shape and Restor", "Aliaxin SV Superior Volume"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "INFINI PREMIUM",
    brand_name: ["Infini Premium Filler B Volum", "Infini Premium Filler Soft Lip", "Infini Premium Filler A Aquabooste", "Infini Premium Filler ", "Infini Premium Filler ", "Infini Premium Filler ", "Infini Premium Filler XL"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Intraline",
    brand_name: ["Intraline On", "Intraline Tw", "Intraline Me", "Intraline M2 Plu", "Intraline M3 Plu", "Intraline M4 Plus"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "JETEMA Co., Ltd.",
    brand_name: ["Starfill Deep Plu", "Starfill Plu", "Starfill Implant Plus"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "JSBIO Co., Ltd.",
    brand_name: ["Sosum ", "Sosum ", "Sosum S"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Juves Laboratoires",
    brand_name: ["Juves Figurha Eyelight", "Juves Figurha Initi", "Juves Figurha Intens", "Juves Simetr", "Juves Figurha Volumo"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "L'ESTHÉTIC",
    brand_name: ["Dermhage Fine Touc", "Dermhage Full Fac", "Dermhage Lip", "Dermhage Volume"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Laboratoires Surface-Paris",
    brand_name: ["Surface Paris Mes", "Surface Paris White with Meso"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Laboratoires Vivacy",
    brand_name: ["Desiria", "Desirial Plus"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "LUMFIL",
    brand_name: ["LUMIFIL L-Lit", "LUMIFIL L-Kis", "LUMIFIL L-Ma", "LUMIFIL Max"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Marllor",
    brand_name: ["Neovelle Classi", "Neovelle Lip", "Neovelle Plus"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Mastelli",
    brand_name: ["Plines", "Plinest Bod", "Plinest Fas", "Plinest Iales", "Plinest Newest"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "MATEX LAB SPA",
    brand_name: ["Neauvia Organic Hydro Delux", "Neauvia Organic Hydro Deluxe Ma", "Neauvia Organic Intens", "Neauvia Organic Intense Flu", "Neauvia Organic Intense Lip", "Neauvia Organic Intense L", "Neauvia Organic Intense Ma", "Neauvia Organic Intense Rheolog", "Neauvia Organic Intense Ros", "Neauvia Organic Stimulat", "Neauvia Organic Stimulate Man"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Maxcore",
    brand_name: ["Beads Max Classic  - ", "Beads Max Classic - S"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Mediderma",
    brand_name: ["Fillderma Harmoniz", "Fillderma Kis", "Fillderma Revitaliz", "Fillderma Volumize"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Medixa",
    brand_name: ["Ejal40 Bio Revitalizing Gel"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Medyglobal",
    brand_name: ["HyalDew Al", "HyalDew Fin", "HyalDew Mi", "HyalDew Shine"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Medytox",
    brand_name: ["Neuramis Dee", "Neuramis Volume"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Merz",
    brand_name: ["Belotero Balanc", "Belotero Intens", "Belotero Lips Shap", "Belotero Lips Contou", "Belotero Sof", "Belotero Volume , Radiess", "Radiesse +"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "NanoPass Technologies Ltd",
    brand_name: ["Fillmed NCTF 135, Fillmed NCTF 135H", "Fillmed NCFT 135HA Alopecia bundl", "Fillmed NCFT 135HA Revitalisation bundl", "Fillmed M-HA 10, Fillmed M-HA 18, Fillmed X-HA3, Fillmed X-HA Volum", "Fillmed Art Fine Line", "Fillmed Art Filler Lip", "Fillmed Art Filler Universa", "Fillmed Art Filler Volume"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Neogenesis Co.",
    brand_name: ["Regenovue Dee", "Regenovue Deep Plu", "Regenovue Fin", "Regenovue Fine Plu", "Regenovue Sub-", "Regenovue Sub-Q Plus"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "NYUMA PHARMA",
    brand_name: ["Hyamira Basi", "Hyamira Fort", "Hyamira Soft"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Oreon Life Science",
    brand_name: ["Re:on Dee", "Re:on Globa", "Re:on Ultra"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "PharmaResearch",
    brand_name: ["Rejuran Rejuvenation with PN"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Professional Derma SA",
    brand_name: ["Jalupr", "Jalupro HM", "Jalupro Super Hydro"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Prollenium",
    brand_name: ["Revanesse", "Revanesse Contour", "Revanesse Kiss", "Revanesse Pure", "Revanesse Shape", "Revanesse Ultra"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Promoitalia",
    brand_name: ["Nucleofill Medium", "Nucleofill Soft Plus", "Nucleofill Strong"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "REGENYAL LABORATORIES",
    brand_name: ["Regenyal Bioregen", "Regenyal Idea", "Regenyal Idea Lips"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "REVITACARE",
    brand_name: ["Cytocare 502", "Cytocare 516", "Cytocare 532", "Cytocare C Line 715", "Cytocare S Line"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "SBS-Med",
    brand_name: ["Dermafill Global Xtra", "Dermafill Lips", "Dermafill Volume Ultra"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Shanghai Reyoungel International Trading Co",
    brand_name: ["Reyoungel Derm", "Reyoungel Derm Deep", "Reyoungel Derm Deep", "Reyoungel Derm Plus"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Sinclair",
    brand_name: ["Perfectha Finelines", "Perfectha Deep", "Perfectha Derm", "Perfectha Subskin", "Ellanse S", "Ellanse E", "Ellanse M", "Ellanse L", "MaiLi Precise", "MaiLi Define ", "MaiLi Volume", "MaiLi Extreme", "Lanluma"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Suisselle Laboratory",
    brand_name: ["apriline Forte", "apriline Normal", "apriline Hydro"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Sunekos Laboratories",
    brand_name: ["Sunekos 200", "Sunekos 1200"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Techderm",
    brand_name: ["Sofiderm Deep", "Sofiderm Derm", "Sofiderm Finelines", "Sofiderm Derm Plus", "Sofiderm Derm Sub-Skin", "Sofiderm Anti-Hair Loss Solution", "Sofiderm Eye Finelines Solution", "Sofiderm SAAS Skin Anti-Acne Solution", "Sofiderm Skin Rejuvenating Solution", "Sofiderm Skin Rejuvenating Solution - Higher Concentration"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Teleta Pharma",
    brand_name: ["e.p.t.q S100", "e.p.t.q S300", "e.p.t.q S500"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Teoxane",
    brand_name: ["Teosyal RHA 1", "Teosyal RHA 2", "Teosyal RHA 3", "Teosyal RHA 4", "Teosyal RHA Kiss", "Teosyal Puresense Ultra Deep", "Teosyal Puresense Ultimate", "Teosyal Puresense Global Action", "Teosyal Puresense Deep Lines", "Teosyal Meso"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "UNKNOWN",
    brand_name: ["Aessoa Deep", "Aessoa Global", "Aessoa Ultra", "Aessoa Shine", "Dermgel Deep", "Dermgel Ultra", "Dermgel Derm", "FINE FILL Deep", "FINE FILL Fine", "FINE FILL Sub Q", "Genius Define", "Genius Enrich", "Genius Smooth", "Genius Ultra", "Ithalya 11", "Ithalya 111", "Ithayla X", "Kairax Deep", "Kairax Fine", "Kairax Sub-Q", "Produktil Injectable Meso", "Profiller Easy", "Profiller Lips", "Profiller Visage", "Produktil Injectable Meso", "RHINO FILL Sub Q", "TONE UP FILL"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "UTH Aesthetics Ltd",
    brand_name: ["UTH Deep 03, UTH Fine 02, UTH Hydrat", "UTH Sub Q"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Vivacy laboratories",
    brand_name: ["Stylage Bi Soft S", "Stylage Bi Soft M", "Stylage Bi Soft L", "Stylage Bi Soft XL", "Stylage Bi Soft Hydromax", "Stylage Bi Soft Hydro", "Stylage Bi Soft Lips", "Stylage Bi Soft Special Lips", "Stylage S", "Stylage M", "Stylage L", "Stylage XL", "Stylage XXL", "Stylage Hydro", "Stylage Hydro Max", "Stylage Special Lips"]
  },
  {
    treatment_category: "Dermal Fillers",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Yuma Medical",
    brand_name: ["Phillex Impression Deep Plus", "Phillex Impression Fine Lips", "Phillex Impression Sub Q Plus"]
  },
  {
    treatment_category: "Fat Dissolving",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Allergan",
    brand_name: ["Kybella", "Belkyra"]
  },
  {
    treatment_category: "Fat Dissolving",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Fox Pharma",
    brand_name: ["Desoface", "Desobody"]
  },
  {
    treatment_category: "Fat Dissolving",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Marllor Biomedical",
    brand_name: ["Aqualyx"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Allwhite LASER AW3",
    brand_name: ["AW3®"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Alma Lasers",
    brand_name: ["Soprano Titanium"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Alma Lasers",
    brand_name: ["Soprano ICE"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Alma Lasers",
    brand_name: ["Soprano ICE Platinum"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Candela",
    brand_name: ["GentleMax Pro"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "DEKA",
    brand_name: ["Again"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "DEKA",
    brand_name: ["Synchro"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "DEKA",
    brand_name: ["Minisilk FT"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "DEKA",
    brand_name: ["Luxea"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "DEKA",
    brand_name: ["Motus AX"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "DEKA",
    brand_name: ["Motus AY"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "DEKA",
    brand_name: ["Motus AZ"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Lumenis",
    brand_name: ["Light Sheer"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Lumenis",
    brand_name: ["Splendor X"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Lynton",
    brand_name: ["LUMINA"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Sinclair",
    brand_name: ["Elysion"]
  },
  {
    treatment_category: "Lasers LED & IPL",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "SkinBase",
    brand_name: ["SkinBase"]
  },
  {
    treatment_category: "PRP Therapy",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Eclipse Aesthetics",
    brand_name: ["Eclipse PRP"]
  },
  {
    treatment_category: "PRP Therapy",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Hebei Weitamei Technology Co., Ltd.",
    brand_name: ["Misfill+ PRP Filler"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Allergan",
    brand_name: ["Juvéderm Hydrate"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Allergan",
    brand_name: ["Juvéderm Volite"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Dermaren",
    brand_name: ["Lumi Eyes"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "FillMed",
    brand_name: ["NCTF 135"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "FillMed",
    brand_name: ["NCTF 135 HA"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "FillMed",
    brand_name: ["NCTF BOOST 135 HA"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Fox Pharma",
    brand_name: ["Seventy Hyal 2000"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Galderma",
    brand_name: ["Restylane Skinboosters Vital"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Galderma",
    brand_name: ["Restylane Vital"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Galderma",
    brand_name: ["Restylane Skinboosters Vital Light"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Galderma",
    brand_name: ["Restylane Vital Light"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "IBSA",
    brand_name: ["Profhilo"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "IBSA",
    brand_name: ["Viscoderm Hydrobooster"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Merz",
    brand_name: ["Belotero Revive"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Merz",
    brand_name: ["BELOTERO® Revive"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "PromoItalia",
    brand_name: ["Nucleofill Strong"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "PromoItalia",
    brand_name: ["Nucleofill Soft"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Sunekos",
    brand_name: ["Performa"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Sunekos",
    brand_name: ["Sunekos Performa"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Sunekos",
    brand_name: ["Sunekos 1200"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Sunekos",
    brand_name: ["Sunekos 200"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Sunekos",
    brand_name: ["Sunekos Body"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Teoxane",
    brand_name: ["TEOSYAL® PURESENSE REDENSITY 1"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Teoxane",
    brand_name: ["TEOSYAL® PURESENSE REDENSITY 2"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Teoxane",
    brand_name: ["Teosyal Puresense Redensity 1"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Teoxane",
    brand_name: ["Teosyal Puresense Redensity 2"]
  },
  {
    treatment_category: "Skin Boosters",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Teoxane",
    brand_name: ["Teoxane Redensity 1"]
  },
  {
    treatment_category: "Skin Treatments",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Alma Laser",
    brand_name: ["Accent Prime"]
  },
  {
    treatment_category: "Skin Treatments",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Bovie Medical",
    brand_name: ["J Plasma"]
  },
  {
    treatment_category: "Skin Treatments",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Eclipse Aesthetics",
    brand_name: ["Eclipse MicroPen"]
  },
  {
    treatment_category: "Skin Treatments",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Endo International",
    brand_name: ["Qwo®"]
  },
  {
    treatment_category: "Skin Treatments",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Eudelo CelluBust",
    brand_name: ["Eudelo"]
  },
  {
    treatment_category: "Skin Treatments",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Fusion GT",
    brand_name: ["Biodermogenesi"]
  },
  {
    treatment_category: "Skin Treatments",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "InMode",
    brand_name: ["Fractora"]
  },
  {
    treatment_category: "Skin Treatments",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Merz",
    brand_name: ["Ulthera", "Ultherapy", "DeepSEE",],
  },
  {
    treatment_category: "Skin Treatments",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "UNKNOWN",
    brand_name: ["Body Ballancer", "BALLANCER®PRO", "Endolift",]
  },
  {
    treatment_category: "Skin Treatments",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "XIO Group",
    brand_name: ["Lumenis laser"]
  },
  {
    treatment_category: "Surgical Treatments",
    market_classification: "6e7a2ea-585e-405a-810a-0ad845d2fd99",
    manufacturer: "Allergan",
    brand_name: ["Natrelle", "Natrelle INSPIRA", "Natrelle® 410 Anatomical Gel", "Natrelle® 133PLUS Tissue Expander",]
  },
  {
    treatment_category: "Surgical Treatments",
    market_classification: "6e7a2ea-585e-405a-810a-0ad845d2fd99",
    manufacturer: "Mentor",
    brand_name: ["MemoryGel", "MemoryShape", "Mentor® CPG Gel", "Mentor® Saline"]
  },
  {
    treatment_category: "Surgical Treatments",
    market_classification: "6e7a2ea-585e-405a-810a-0ad845d2fd99",
    manufacturer: "Sientra",
    brand_name: ["Silicone Gel Breast Implants"]
  },
  {
    treatment_category: "Surgical Treatments",
    market_classification: "6e7a2ea-585e-405a-810a-0ad845d2fd99",
    manufacturer: "Sound Surgical Technologies",
    brand_name: ["VASER Lipo"]
  },
  {
    treatment_category: "Thread Lifts",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Sinclair Pharma",
    brand_name: ["Silhouette InstaLift", "Silhouette Soft",]
  },
  {
    treatment_category: "Thread Lifts",
    market_classification: "a615611-6863-4fb7-903f-94c8c11f0652",
    manufacturer: "Sutura Medical Technology LLC",
    brand_name: ["NovaThreads"]
  }
]
