import React, {useState} from "react";
import {useApolloClient, useMutation} from "@apollo/client";
import {DELETE_TERRITORY} from "../../../../graphql/queries/territory";
import {CButton, CModal, CModalBody, CModalHeader} from "@coreui/react";

const DeleteTerritory = ({id, onComplete = async () => void 0}) => {
  const [visible, setVisible] = useState(false)
  const client = useApolloClient()

  const [deleteTerritory, {loading}] = useMutation(
    DELETE_TERRITORY, {
      variables: { id },
      onCompleted() {
        client.cache.modify({
          id: "ROOT_QUERY",
          fields: {
            search(current) {
              return {
                ...current,
                reducedSegments: undefined
              }
            }
          }
        })
        client.cache.gc()
        setVisible(false)
        onComplete()
      }
    }
  )
  return <>
    <CModal size={"sm"} visible={visible} onClose={() => setVisible(false)}>
      <CModalHeader>Confirm Delete?</CModalHeader>
      <CModalBody className={'d-flex justify-content-between'}>
        <CButton color={"success"} size={'sm'} onClick={() => deleteTerritory()}>
          Confirm
        </CButton>
        <CButton color={"primary"} variant={"outline"} size={'sm'} onClick={() => setVisible(false)}>
          Cancel
        </CButton>
      </CModalBody>
    </CModal>
    <CButton onClick={() => setVisible(true)} color={'danger'} className={'ms-auto'}>
      Delete
    </CButton>
  </>
}

export default DeleteTerritory
