import React from 'react'

const ColourChip = ({ colour = '#ccc', center = false, size, style = {} }) => {
  style = {
    ...style,
    backgroundColor: colour,
  }
  if (size) {
    style = {
      ...style,
      width: size,
      height: size,
    }
  }
  return <span className={`colour-chip${center ? ' center' : ''}`} style={style}></span>
}

export default ColourChip
