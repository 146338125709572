import React, { useEffect, useState } from 'react'
import { CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow } from '@coreui/react'
import SearchPreviewPane from './SearchPreviewPane'
import { SegmentGroup } from '../../../Segments'
import { segments } from '../../../../tools/set-seg-tag'
import Tag from '../../../Tag'

const PreviewSearch = ({ id, item }) => {
  const [visible, setVisible] = useState(false)

  const [search, setSearch] = useState([])

  useEffect(() => {
    if (id && item) {
      const finalOps = {}
      segments.forEach((segment) => {
        finalOps[segment.name] = segment.op
      })

      const dsItem = { ...item, external_id: item.locations ?? [] }

      const filter = Object.keys(dsItem)
        .filter((field) => {
          if (field === 'external_id') return true
          const find = segments.findIndex((s) => s.name === field)
          return find !== -1
        })
        .filter((key) => dsItem[key].length > 0)
        .map((key) => {
          return {
            field: key,
            values: dsItem[key],
            operation: 'OR',
          }
        })

      setSearch(filter)
    }
    return () => setSearch([])
  }, [item, id])

  return (
    <>
      <CModal fullscreen={true} visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader>Preview Search "{item.name}"</CModalHeader>
        <CModalBody>
          <SearchPreviewPane id={id} terms={search} />
          <CRow className={'mb-2'}>
            <CCol>
              {Object.keys(item).filter((k) => k !== 'freeform' && k !== 'ops').length > 0 && (
                <SegmentGroup>
                  {segments
                    .filter((seg) => item[seg.name] && item[seg.name].length > 0)
                    .map((seg) => (
                      <React.Fragment key={seg}>
                        {item[seg.name].map((n) => (
                          <Tag
                            key={n}
                            color={'primary'}
                            className={`search-segment-tag ${seg.color}`}
                          >
                            {n}
                          </Tag>
                        ))}
                      </React.Fragment>
                    ))}
                </SegmentGroup>
              )}
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton color={"primary"} onClick={() => setVisible(false)}>Close Preview</CButton>
        </CModalFooter>
      </CModal>
      <CButton
        color={"primary"}
        onClick={() => {
          setVisible(true)
        }}
      >
        Preview Search
      </CButton>
    </>
  )
}

export default PreviewSearch
