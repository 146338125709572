import React, { useCallback, useEffect, useState } from 'react'
import { useMapEvents } from 'react-leaflet'
import { makeRect } from '../../../../../../tools/geo'
import { useLazyQuery } from '@apollo/client'
import { AREAS_IN_VIEW } from '../../../../../../graphql/queries/geo'
import ViewedArea from './ViewedArea'
import SelectedInView from './SelectedInView'

const TerritoryLayer = ({ selected = [], onSelect = (id = '') => void 0 }) => {
  // const map = useMap()
  const [shapes, setShapes] = useState([])
  const [rect, setRect] = useState([])
  const [level, setLevel] = useState(1)
  const [selectedRegions, setSelectedRegions] = useState([])

  const [loadAreas, { loading }] = useLazyQuery(AREAS_IN_VIEW, {
    onCompleted({ geoAreasWithinView: { data = [] } }) {
      setShapes(data)
    },
    onError(e) {
      console.error(e)
    },
  })

  const zoomLevel = (z = 1) => {
    if (z < 11) {
      setLevel(2)
    } else {
      setLevel(1)
    }
  }

  const map = useMapEvents({
    moveend({ target }) {
      const bou = target.getBounds()
      const r = makeRect(bou)
      setRect(r)
      const { _zoom } = target
      let l = 1
      zoomLevel(_zoom)
    },
  })

  useEffect(() => {
    if (map) {
      setTimeout(() => {
        const b = map.getBounds()
        setRect(makeRect(b))
        zoomLevel(map._zoom)
      }, 400)
    }
  }, [])

  useEffect(() => {
    loadAreas({
      variables: {
        level: level,
        view: rect,
        filter: [],
      },
    })
  }, [selected, rect, level])

  const mergeSelectedShapes = useCallback(
    (s = []) => {
      const merged = [...selectedRegions]
      s.forEach((sh) => {
        const comp = selectedRegions.find((shape) => shape.id === sh)
        const shape = shapes.find((shape) => shape.id === sh)
        if (shape && !comp) {
          merged.push(shape)
        }
      })

      return merged
    },
    [shapes, selectedRegions],
  )

  useEffect(() => {
    const merged = mergeSelectedShapes(selected)
    const trimmed = merged.filter((m) => selected.includes(m.id))
    setSelectedRegions(trimmed)
  }, [selected, shapes])

  useEffect(() => {
    const s = shapes.filter((shape) => selected.includes(shape.code))
  }, [shapes])

  return (
    <>
      <SelectedInView selected={selected} regions={selectedRegions} level={level} />
      <ViewedArea selected={selected} regions={shapes} onClick={onSelect} level={level} />
    </>
  )
}

export default TerritoryLayer
