import React, { useState } from 'react'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CSpinner } from '@coreui/react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ASSIGN_SUB_USER_SEARCH, READ_SEARCHES } from '../../../../../graphql/queries/search-manage'
import SearchList from './SearchList'

const SetUserSearch = ({ user, onComplete = () => void 0 }) => {
  const [visible, setVisible] = useState(false)
  const [
    listSearches,
    { loading, data: { ListUserSearch: { entries } } = { ListUserSearch: { entries: [] } } },
  ] = useLazyQuery(READ_SEARCHES)
  const [setSearch, { loading: setLoading }] = useMutation(ASSIGN_SUB_USER_SEARCH)

  const loadModal = () => {
    setVisible(true)
    listSearches()
  }

  const onSelect = async (searchId) => {
    await setSearch({ variables: { searchId, subUserId: [user.id], removeId: [] } })
    onComplete()
  }

  return (
    <>
      <CModal visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader>Assign Search</CModalHeader>
        <CModalBody>
          {loading && <CSpinner />}
          {!loading && <SearchList searchId={user.search} searches={entries} onSelect={onSelect} />}
        </CModalBody>
        <CModalFooter>
          <CButton color={"primary"} onClick={() => setVisible(false)}>Done</CButton>
        </CModalFooter>
      </CModal>
      <CButton color={'secondary'} onClick={() => loadModal()}>
        Assign Search
      </CButton>
    </>
  )
}

export default SetUserSearch
