import React, { useEffect, useState } from 'react'
import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon-2x.png'
import 'leaflet/dist/leaflet.css'
import { MapContainer, TileLayer } from 'react-leaflet'
import TerritoryLayer from './Components/TerritoryLayer'

const marker = new L.icon({
  iconUrl: icon,
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
})

const TerritoryMap = ({onChange = (codes = []) => void(0), selected = undefined, findBounds = true}) => {

  const [internalSelected, setInternalSelected] = useState(selected)

  const selectCode = (id) => {
    let nSelected = []
    if(typeof selected !== "undefined") {
      nSelected = [...selected]
    } else {
      nSelected = [...internalSelected]
    }
    const idx = nSelected.findIndex((s) => s === id)
    if(idx !== -1) {
      // Remove if not present
      nSelected.splice(idx, 1)
    } else {
      // Check if there are numbers
      if(/\d/.test(id)) {
        // It already has a number. Get the alphas and remove the parent?
        const parts = id.split("/")
        const stripped = `POSTCODE_AREA/${parts[1].replace(/\d+\D*/g, "")}`
        const ids = nSelected.findIndex((s) => s === stripped)
        if(ids !== -1) {
          nSelected.splice(ids, 1)
        }
        nSelected.push(id)

      } else {
        // Remove any children of this code
        const subsets = internalSelected
          .filter((f) => {
            const test = `^${id}\\d+\D*`
            const ev = new RegExp(test, "i")
            // TODO this needs to be made generic somehow
            return !ev.test(f.replace("POSTCODE_DISTRICT", "POSTCODE_AREA"))
          })

        nSelected = Array.from(new Set([...subsets, id]))
      }
    }
    if(typeof selected !== "undefined") {
      onChange(nSelected)
    } else {
      setInternalSelected(nSelected)
    }
  }

  useEffect(() => {
    if(typeof selected === "undefined") {
      onChange(internalSelected)
    }
  }, [internalSelected]);

  useEffect(() => {
    setInternalSelected(selected)
  }, [selected]);

  return <>
    <MapContainer
      // Normally I would leave this in
      attributionControl={false}
      style={{height: "50rem", position: "initial", maxWidth: "80rem"}}
      center={[51.505, -0.09]} zoom={13} scrollWheelZoom={true}>
      <TileLayer
        attribution={"&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors"}
        url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
      />
      {/*<SelectedShapes codes={selected} onSelect={selectCode} findBounds={findBounds} />*/}
      <TerritoryLayer onSelect={selectCode} selected={selected} />
    </MapContainer>
  </>
}

export default TerritoryMap
