import React from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import icon from '../../../assets/pin.png'
import colourIcon from '../../Utility/Map/colour-icon'

const ClinicMap = ({location: {lat, lon} = {lat: 0, lon: 0}}) => {
  return <>
    <MapContainer
      attributionControl={false}
      style={{maxWidth: "100%", width: "30rem", height: "20rem", margin: "0 auto"}}
      center={[lat, lon]}
      zoom={15}
      zoomControl={false}
      scrollWheelZoom={false}
      doubleClickZoom={false}
      dragging={false}
    >
      <TileLayer
        attribution={
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }
        url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
      />
      <Marker position={[lat, lon]} icon={colourIcon()} draggable={false} />
    </MapContainer>
  </>
}

export default ClinicMap
