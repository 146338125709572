import Cookies from "universal-cookie";

const cookies = new Cookies(null, {path: "/", maxAge: 345600})

export function getAuthToken() {
  return localStorage.getItem("AUTH_TOKEN")
}

export function setAuthToken(token) {
  cookies.set("AUTH_TOKEN", token)
  return localStorage.setItem("AUTH_TOKEN", token)
}

export function clearAuthToken() {
  cookies.remove("AUTH_TOKEN")
  return localStorage.removeItem("AUTH_TOKEN")
}
