import {Link, useParams} from "react-router-dom";
import {
  CAlert,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CSpinner,
  CTable,
  CTableDataCell,
  CTableHead, CTableHeaderCell,
  CTableRow, CTooltip
} from "@coreui/react";
import React from "react";
import {gql, useQuery} from "@apollo/client";
import CIcon from "@coreui/icons-react";
import {cilInfo} from "@coreui/icons";

const CLINIC_COMPETITION = gql`
  query TreatmentCompetition($externalId: String!) {
    clinicLocalCompetition(externalId:  $externalId) {
      competitors {
        clinic {
          external_id name
        }
        treatments
        upsellCount
      }
      totalCount totalUpsellCount
    }
  }
`;

const CompetitorTreatments = ({externalId}) => {

  const {loading, data: {clinicLocalCompetition: {competitors, totalCount: total, totalUpsellCount: totalUpsell}} = {clinicLocalCompetition: {competitors: [], totalCount: 0, totalUpsellCount: 0}}} = useQuery(CLINIC_COMPETITION, {
    variables: {
      externalId
    }
  })

  if(loading) {
    return <>
      <CCard className={"h-100"}>
        <CCardHeader>
          <CCardTitle>
            Treatment Providers
          </CCardTitle>
        </CCardHeader>
        <CCardBody>
          <CSpinner />
        </CCardBody>
      </CCard>
    </>
  }

  return <>
    <CCard className={"h-100"}>
      <CCardHeader>
        <CCardTitle>
          Treatment Providers
        </CCardTitle>
      </CCardHeader>
      <CCardBody>

        <CAlert color="primary" className="d-flex align-items-center" style={{fontSize: "0.6rem"}}>
          <CIcon icon={cilInfo} className="flex-shrink-0 me-2" width={24} height={24}/>
          <div>Clinics in the area that have largest number of similar treatments to this clinic.
          </div>
        </CAlert>
        <CTable borderless small>
          <CTableRow>
            <CTableDataCell>
              <CTooltip content={"This covers the total number of similar providers in the local authority local authority, where the provider is based."}><span><strong>Similar Providers Found</strong> <CIcon icon={cilInfo} size={"sm"}/></span></CTooltip>
            </CTableDataCell>
            <CTableDataCell>{total}</CTableDataCell>
          </CTableRow>
          <CTableRow>
            <CTableDataCell>
              <CTooltip content={"This covers the total number of total healthcare providers in the local authority local authority, where the provider is based."}><span><strong>Total Healthcare Providers Found</strong> <CIcon icon={cilInfo} size={"sm"}/></span></CTooltip>
            </CTableDataCell>
            <CTableDataCell>{totalUpsell}</CTableDataCell>
          </CTableRow>
        </CTable>
        <CTable borderless small>
          <CTableHead>
            <CTableHeaderCell>Competitor Name</CTableHeaderCell>
            <CTableHeaderCell>Competitor Treatments</CTableHeaderCell>
          </CTableHead>
          {competitors.map(({clinic, treatments = [], upsellCount = 0}) => {
            return <CTableRow key={clinic.external_id}>
              <CTableDataCell><Link to={"/clinic/" + clinic.external_id}>{clinic.name}</Link></CTableDataCell>
                <CTableDataCell>{treatments.join(", ")}{upsellCount > 0 && <span style={{fontSize: "0.6rem"}}> (+ {upsellCount} more outside your subscription)</span>}</CTableDataCell>
            </CTableRow>
          })}
        </CTable>
      </CCardBody>
    </CCard>
  </>
}

export default CompetitorTreatments
