import React, { useEffect, useState } from 'react'
import { SideSegment } from '../index'

const Territory = ({ segment: inSegment, ...props }) => {
  const [customOpts, setCustomOpts] = useState([])
  const [segment, setSegment] = useState({})
  useEffect(() => {
    setSegment({ ...inSegment, strings: [] })
    if (inSegment.strings) {
      setCustomOpts(
        [...inSegment.strings].sort((a, b) => {
          if (a.name > b.name) {
            return 1
          } else if (a.name < b.name) {
            return -1
          }
          return 0
        }),
      )
    }
  }, [inSegment])
  return (
    <>
      <SideSegment {...props} segment={segment} customOpts={customOpts} />
    </>
  )
}

export default Territory
