import React from "react";
import PropTypes from "prop-types";
import Level from "./Level";

const Levels = ({ visible, ...props}) => {
  if(!visible) {
    return <></>
  }
  return <div className={"segment-multilevel-leaves"}>
    <Level {...props} level={0} />
  </div>
}

Levels.propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.any,
  onChange: PropTypes.func,
  visible: PropTypes.bool,
}

export default Levels

