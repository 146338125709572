import React, { useEffect, useState } from 'react'
import {CButton, CFormCheck, CFormSwitch} from '@coreui/react'

const SelectItemOperation = ({
  text,
  selected,
  operation,
  segment,
  name,
  value,
  onClick = (segment, operation, tag) => void 0,
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const [props, setProps] = useState({ variant: 'outline' })

  useEffect(() => {
    let seg = segment
    if(!!operation) {
      seg = `${segment}:${operation}`
    }

    if (selected.hasOwnProperty(seg) && selected[seg].includes(value ?? name.toLowerCase())) {
      setIsSelected(true)

      setProps({})
    } else {
      setIsSelected(false)
      setProps({ variant: 'outline' })
    }
  }, [selected, segment, name])

  return (
    <span style={{display: "block"}}>

      <CButton
        size={"sm"}
        color={'success'}
        title={text}
        {...props}
        onClick={() => onClick(segment, operation, value ?? name.toLowerCase())}
      >
        &nbsp;
        &nbsp;
      </CButton>
    </span>
  )
}

export default SelectItemOperation
