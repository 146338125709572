import React, {useEffect, useRef, useState} from "react";
import {GeoJSON} from "react-leaflet";
import {featureCollection} from "@turf/helpers";

const SelectedInView = ({selected = [], regions = [], onSelect = (area = '') => void 0, level = 1}) => {
  const [combinedData, setCombinedData] = useState({})
  const geoCurrent = useRef()

  useEffect(() => {
    if(regions && regions.length > 0) {
      const features = regions
        .filter((region) => region.level !== level)
        .map((region) => {
        const feature = region.geojson.features[0]
        return {
          ...feature,
          properties: {
            id: region.territory_id,
            territory_id: region.territory_id,
            code: region.code
          }
        }
      })
      const f = featureCollection(features)
      setCombinedData(f)
    } else {
      setCombinedData([])
    }
  }, [selected, regions, level])

  if(!combinedData || Object.keys(combinedData).length === 0) {
    return <></>
  }

  return <>
    <GeoJSON
      interactive={false}
      key={JSON.stringify(combinedData.features)}
      ref={geoCurrent}
      data={combinedData}
      style={{
        color: "#4aa2fd",
      }}
    />
  </>
}

export default SelectedInView
