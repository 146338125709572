import React from 'react'
import { CListGroupItem } from '@coreui/react'
import SelectItemOperation from './SelectItemOperation'
import {opTexts} from "../../../../tools/set-seg-tag";

const SegmentListItem = ({
  selected,
  item,
  onChange = (segment, tag) => void 0,
  className = 'segment-item-controls',
  ops = [],
  ...props
}) => {
  return (
    <CListGroupItem className={className}>
      <div>{item.renderName ?? item.name}</div>
      <div className={"d-flex justify-content-around"} style={{width: "16rem"}}>
        {ops.map((op, id) => <React.Fragment key={`${op}-${id}`}>
          <SelectItemOperation
            text={opTexts[op] ?? ""}
            operation={op}
            selected={selected}
            onClick={(segment, operation, tag) => {
              onChange(`${segment}:${operation}`, tag)
            }}
            {...item}
          />{' '}

        </React.Fragment>)}
        {(!ops || ops.length === 0) && <SelectItemOperation
          text={""}
          operation={""}
          selected={selected}
          onClick={(segment, operation, tag) => {
            onChange(segment, tag)
          }}
          {...item} />}
      </div>
      {props.children}
    </CListGroupItem>
  )
}

export default SegmentListItem
