import React, { useEffect, useState } from 'react'
import { SegmentTagList } from '../../../Segments'
import {nonSubSegs, returnSegments, setSegList, setSegTag, shornSeg, swapSegs} from '../../../../tools/set-seg-tag'
import { CButton, CCol, CRow, CSpinner } from '@coreui/react'
import { useNavigate } from 'react-router-dom'
import {useQuery} from "@apollo/client";
import {READ_SEGMENTS} from "../../../../graphql/queries/search-manage";

const formOps = ['or', 'not']

const excludeSegs = [...nonSubSegs, 'data_source_name', 'locations', 'catalog_data']

const SearchForm = ({
  existingSearch = {},
  // mappedSegments = [],
  onComplete = async (search) => void 0,
}) => {
  const [search, setSearch] = useState({})
  const nav = useNavigate()
  const [locationTerms, setLocationTerms] = useState([])
  const [mappedSegments, setMappedSegments] = useState([])

  const {
    data: { SearchSegmentData } = {
      SearchSegmentData: {},
    },
    loading: dataLoading,
  } = useQuery(READ_SEGMENTS, {
    variables: {
      segments: returnSegments(excludeSegs).map((s) => s.name)
    },
    fetchPolicy: "network-only",
  })

  useEffect(() => {
    if (!dataLoading) {
      const ms = returnSegments(excludeSegs).map((seg) => {
        let strings = []
        if (SearchSegmentData.hasOwnProperty(seg.name)) {
          strings = SearchSegmentData[seg.name]
        }

        let {component} = seg

        if(seg.hasOwnProperty("cComponent")) {
          component = seg.cComponent
        }

        return {
          ...seg,
          component,
          strings,
        }
      })
      // .filter((seg) => seg.strings.length > 0)
      setMappedSegments(ms)
    }
  }, [dataLoading])

  useEffect(() => {
    if (existingSearch) {
      const ns = {}
      Object.keys(existingSearch)
        .filter((k) => Array.isArray(existingSearch[k]))
        .forEach((k) => (ns[k] = existingSearch[k]))
      setSearch(ns)
    }
  }, [existingSearch])

  const setTag = (_, segment, tag) => {
    const newSearch = setSegTag(search, segment, tag)
    setSearch(newSearch)
  }

  const swapSegments = (segment, tag) => {
    const swapList = swapSegs(search, segment, tag, formOps)
    const s = setSegList(search, swapList)
    setSearch(s)
  }

  const setAll = (segment, tags = []) => {
    let existing = []
    if (search.hasOwnProperty(segment) && search[segment].length > 0) {
      existing = search[segment]
      if (existing.length === tags.length) {
        setSearch({ ...search, [segment]: [] })
      } else {
        setSearch({ ...search, [segment]: tags })
      }
    } else {
      const newSearch = { ...search, [segment]: tags }
      setSearch(newSearch)
    }
  }

  const setLocations = (locations = []) => {
    const newSearch = { ...search, locations }
    setSearch(newSearch)
  }

  const saveSearch = () => {
    window.scrollTo(0, 0)
    const finSearch = {}
    const segs = mappedSegments.map((s) => s.name)
    Object.keys(search)
      .filter((k) => k.includes(":"))
      .filter((k) => segs.includes(shornSeg(k)))
      .forEach((k) => finSearch[k] = search[k])
    onComplete(finSearch)
  }

  if (mappedSegments.length === 0) {
    return <CSpinner />
  }

  return (
    <>
      <CRow>
        <CCol md={6} className={'mb-3'}>
          <div className={'d-flex justify-content-start flex-wrap flex-grow-1'}>
            {mappedSegments.map((segment) => {
              return (
                <segment.component
                  onChange={swapSegments}
                  segment={segment}
                  segmentSearch={search[segment.name] ?? []}
                  search={search}
                  showOps={false}
                  ops={formOps}
                  key={segment.name}
                  onSelectAll={() => setAll(segment.name, segment.strings)}
                />
              )
            })}
          </div>
          <SegmentTagList
            key={JSON.stringify(search)}
            mappedSegments={mappedSegments}
            search={search}
            onRemove={setTag}
          />
        </CCol>
        {/*<CCol md={6} className={'mb-3'}>*/}
        {/*  <h5>Locations</h5>*/}
        {/*  <div className={'mb-3 mt-3'}>*/}
        {/*    <FindLocations*/}
        {/*      id={search.id ?? 'CREATE'}*/}
        {/*      selected={search.locations ?? []}*/}
        {/*      onComplete={setLocations}*/}
        {/*      search={search}*/}
        {/*    />{' '}*/}
        {/*    <ManualLocationList />*/}
        {/*  </div>*/}
        {/*  {search.locations.length === 0 && (*/}
        {/*    <CAlert color={'info'}>No locations have been defined for this search.</CAlert>*/}
        {/*  )}*/}
        {/*  {search.locations.length > 0 && (*/}
        {/*    <ListLocations*/}
        {/*      search={search}*/}
        {/*      locations={search.locations}*/}
        {/*      onRemove={(tag) => {*/}
        {/*        setTag('locations', tag)*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</CCol>*/}
      </CRow>
      <CRow>
        <CCol>
          <CButton
            color={"primary"}
            onClick={saveSearch}
          >
            Save
          </CButton>{' '}
          <CButton color={"primary"} onClick={() => nav(-1)}>Cancel</CButton>
        </CCol>
      </CRow>
    </>
  )
}

export default SearchForm
