import React, {useEffect, useState} from "react";
import {CCard, CCardBody, CCardHeader, CCardTitle} from "@coreui/react";
import {Link} from "react-router-dom";
import {CSmartTable} from "@coreui/react-pro";
import {useQuery} from "@apollo/client";
import {LIST_TERRITORY} from "../../../graphql/queries/territory";
import DeleteTerritory from "./Components/DeleteTerritory";

const Territories = () => {
  const [tableEntries, setTableEntries] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [pages, setPages] = useState(1)
  const {data: {ListTerritory: {entries, totalCount}} = {ListTerritory: {entries: [], totalCount: 0}}, loading, refetch} = useQuery(
    LIST_TERRITORY,
    {
      variables: {
        page,
        limit,
      },
      onCompleted({ListTerritory: entries = []}) {}
    }
  )

  useEffect(() => {
    let p = 1
    if (totalCount > 0) {
      p = Math.ceil(totalCount / limit)
      if (p < 1) {
        p = 1
      }
    }
    setPages(p)
  }, [totalCount]);

  useEffect(() => {
    if(!loading && entries) {
      // Destructure so we can manipulate
      setTableEntries(entries.map((entry) => ({...entry})))
    }
  }, [loading, entries]);

  const columns = [
    {
      key: "name",
      _style: {
        width: "25%"
      }
    },
    {
      key: "description",
    },
    {
      key: "count",
      label: "Selected Regions",
      _style: {
        textAlign: "center",
      }
    },
    {
      key: "options",
      label: "Options",
      _style: {
        width: "20%",
        minWidth: "10rem",
      }
    }
  ]

  return <>
    <CCard>
      <CCardHeader>
        <CCardTitle>Manage Territories</CCardTitle>
      </CCardHeader>
      <CCardBody>
        <Link to={"create"} className={"btn btn-primary"}>Create New Territory</Link>
        <CSmartTable
          className={"mt-3"}
          columns={columns}
          scopedColumns={{
            name: (item) => (<td>
              <Link to={`edit/${item.id}`}>{item.name}</Link>
            </td>),
            description: ({description = ""}) => (<td>
              {description && description.substring(0, 255)}
            </td>),
            count: ({codes = []}) => (<td align={"center"}>
              {codes.length}
            </td>),
            options: (item) => (<td align={"right"}>
              <DeleteTerritory id={item.id} onComplete={async () => void refetch()} />
            </td>)
          }}
          items={tableEntries}
          selectable={false}
          itemsNumber={totalCount}
          pagination={pages > 1}
          paginationProps={{
            activePage: page,
            onActivePageChange: (activePage) => setPage(activePage),
            pages
          }}
        />
      </CCardBody>
    </CCard>
  </>
}

export default Territories
