import React from "react";
import {FormField, Input} from "../../../Form";
import {CButton, CCol, CRow, CSpinner} from "@coreui/react";
import Form, {useForm} from "rc-field-form";

const ListForm = ({onComplete = async (values) => void(0), loading = false, locations = [], horizontal = false, disabled = false}) => {
  const [form] = useForm()

  return <Form
    form={form}
    onFinish={async (values) => {
      if(!disabled) {
        onComplete({...values, locations})
      }
    }}
  >
    <FormField
      horizontal={horizontal}
      name={"name"}
      label={"List Name"}
      rules={[{required: true, message: "List name is required"}]}
    >
      <Input onKeyUp={(e) => {
        if(e.code === "Enter"){
          form.submit()
        }
      }} />
    </FormField>
    <CRow className={"mb-3"}>
      <CCol>
        <CButton color={"primary"} disabled={disabled} type={"submit"}>{loading && <CSpinner size={"sm"} />}Save</CButton> <CButton onClick={() => form.resetFields()}>Reset</CButton>
      </CCol>
    </CRow>
  </Form>
}

export default ListForm
