import React from 'react'
import { Link, useMatches } from 'react-router-dom'

import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react'

const AppBreadcrumb = () => {
  const matches = useMatches()

  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => ({ name: match.handle.crumb(match), path: match.pathname }))

  return (
    <>
      <CBreadcrumb className="m-0 ms-2">
        {crumbs.map((crumb, id) => {
          if (id + 1 === crumbs.length) {
            return <CBreadcrumbItem key={id}>{crumb.name}</CBreadcrumbItem>
          } else {
            return (
              <CBreadcrumbItem key={id}>
                <Link to={crumb.path}>{crumb.name}</Link>
              </CBreadcrumbItem>
            )
          }
        })}
      </CBreadcrumb>
    </>
  )
}

export default React.memo(AppBreadcrumb)
