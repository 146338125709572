import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBook,
  cilCalculator,
  cilDescription,
  cilGroup,
  cilLibrary,
  cilList,
  cilPuzzle,
  cilSearch
} from '@coreui/icons'
import { CNavItem } from '@coreui/react'
import {isFeatureFlagged} from "./tools/feature-flag";
const _nav = [
  {
    // component: CNavItem,
    name: 'Dashboard',
    to: '/',
    icon: <CIcon icon={cilCalculator} customClassName={'nav-icon ms-2'} />,
    badge: isFeatureFlagged('dashboard-map-search')
      ? {}
      : {
          color: 'info',
          text: 'NEW',
        },
  },
  {
    as: CNavItem,
    name: 'My Account',
    to: '/myaccount',
    icon: <CIcon icon={cilDescription} customClassName={'nav-icon ms-2'} />,
  },
  {
    as: CNavItem,
    name: 'My Lists',
    to: '/my-lists',
    icon: <CIcon icon={cilList} customClassName={'nav-icon ms-2'} />,
  },
  {
    as: CNavItem,
    name: 'Glossary',
    to: '/glossary',
    icon: <CIcon icon={cilBook}  customClassName={'nav-icon ms-2'} />,
  },
  {
    as: CNavItem,
    name: 'Users',
    to: '/manage/users',
    icon: <CIcon icon={cilGroup} customClassName={'nav-icon ms-2'} />,
    roles: ['sub_user_manage'],
  },
  {
    as: CNavItem,
    name: 'Searches',
    to: '/manage/searches',
    icon: <CIcon icon={cilSearch} customClassName={'nav-icon ms-2'} />,
    roles: ['sub_search_manage'],
  },
  {
    as: CNavItem,
    name: 'Territories',
    to: '/manage/territories',
    icon: <CIcon icon={cilPuzzle} customClassName={'nav-icon ms-2'} />,
  },
]
export default _nav
