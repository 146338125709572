import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilPlaylistAdd } from '@coreui/icons'

const ClinicListButton = (props) => (
  <span
    {...props}
    style={{
      color: 'var(--cui-body-color)',
      fontSize: '0.9rem',
      display: 'inline-block',
      marginLeft: '0.4rem',
      cursor: 'pointer',
      userSelect: 'none',
    }}
  >
    <CIcon icon={cilPlaylistAdd} /> Add to list
  </span>
)

export default ClinicListButton
