import React from 'react'
import {
  CAlert,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableRow,
  CTooltip,
} from '@coreui/react'
import { cilInfo } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import BetaBadge from '../../Utility/BetaBadge'

const HCPContacts = ({ hcpContacts = [] }) => {
  return (
    <CCard className={'h-100'}>
      <CCardHeader>
        <CCardTitle>
          <CTooltip
            content={
              "Professional contact information discovered from this location's internet presence."
            }
          >
            <span>
              Website Contacts <CIcon icon={cilInfo} size={'sm'} />{' '}
            </span>
          </CTooltip>
          <BetaBadge style={{ float: 'right' }} />
        </CCardTitle>
      </CCardHeader>
      <CCardBody>
        {(!hcpContacts || hcpContacts.length === 0) && (
          <CAlert
            color={'primary'}
            className={'d-flex align-items-center'}
            style={{ fontSize: '0.6rem' }}
          >
            <CIcon icon={cilInfo} className="flex-shrink-0 me-2" width={24} height={24} />
            <div>
              We have not yet found any contacts. Please check back later, or contact your Rare
              Account manager if you'd like to learn more.
            </div>
          </CAlert>
        )}
        {hcpContacts.length > 0 && (
          <CTable borderless small>
            <CTableBody>
              <>
                {hcpContacts.map((contact, id) => (
                  <CTableRow key={`hcp-contact-${id}`}>
                    <CTableDataCell className={'w-25'}>
                      <b>{contact.name}</b>
                    </CTableDataCell>{' '}
                    <CTableDataCell>{contact.jobTitle}</CTableDataCell>
                  </CTableRow>
                ))}
              </>
            </CTableBody>
          </CTable>
        )}
      </CCardBody>
    </CCard>
  )
}

export default HCPContacts
