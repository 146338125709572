import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CBadge, CListGroup, CListGroupItem } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilChevronLeft, cilChevronRight } from '@coreui/icons'
import SegmentListItem from '../SegmentListItem'

const selectedChildrenCount = (search, children) => {
  if (!children || children.length === 0) {
    return 0
  }

  let c = 0
  children.forEach((child) => {
    let iC = 0
    if (child.hasOwnProperty('children')) {
      iC = selectedChildrenCount(search, child.children)
    }
    if (search.hasOwnProperty(child.segment)) {
      if (search[child.segment].includes(child.name.toLowerCase())) {
        iC = iC + 1
      }
    }
    c = c + iC
  })
  return c
}

const displayChildrenCount = (search, children) => {
  const c = selectedChildrenCount(search, children)
  if(c > 0) {
    return <CBadge color={"secondary"}>{c}</CBadge>
  } else {
    return <></>
  }
}

const Level = ({onCloseEnd, onCloseStart, level = 0, parents = [], items, selected = {}, onChange = (parents, segment, value) => void(0), onOpen, ops = [] }) => {
  const [children, setChildren] = useState([])
  const [selectedId, setSelectedId] = useState("")
  const [segment, setSegment] = useState("")
  const [nextParents, setNextParents] = useState([])
  const [pull, setPull] = useState(false)
  const [push, setPush] = useState(false)
  const [left, setLeft] = useState("0%")

  const closeEnd = () => {
    setChildren([])
    setSelectedId("")
  }

  const closeStart = () => {
    closeForChild()
  }

  const closeThis = () => {
    closeForChild()
    onCloseStart()
    // TODO make this magical
    onCloseEnd()
  }

  const openForChild = () => {
    // setPull(true)
    // setPush(false)
  }

  const closeForChild = () => {
    // setPull(false)
    // setPush(true)
  }

  const selectChild = (item) => {
    if (!selectedId) {
      setSelectedId(item.id)
      setChildren(item.children)
      openForChild()
    } else {
      if (selectedId === item.id) {
        setSelectedId('')
        setChildren([])
        closeForChild()
      } else {
        setSelectedId(item.id)
        setChildren(item.children)
        openForChild()
      }
    }
  }

  useEffect(() => {
    if(items && items.length > 0) {
      const {segment: seg = ""} = items[0]
      if(seg && seg.length > 0) {
        setSegment(seg)
      }
    }
  }, [items])

  useEffect(() => {
    if(selectedId) {
      setNextParents([...parents, selectedId])
      // setPull(true)
    } else {
      // setPull(false)
    }
  }, [selectedId]);

  useEffect(() => {
    if(level > 0) {
      // setLeft("100%")
      setPull(true)
    }
    return () => {
      setPull(false)
      setPush(true)
      setSelectedId("")
      setChildren([])
    }
  }, []);

  return (
    <>
      <div
        className={`segment-multilevel-leaf ${pull?' pull':''}${push?' push':''}`}
        style={{left}}
      >
        <CListGroup>
          <>{level > 0 && <CListGroupItem>
            <div className={"segment-multilevel-closer"} onClick={() => closeThis()}>
              <CIcon icon={cilChevronLeft} size={"lg"} />
            </div>
          </CListGroupItem> }</>
          <>
            {items.map((item, n) => <React.Fragment key={`level-${level}-${n}`}>
              <SegmentListItem
                item={item}
                selected={selected}
                onChange={(segment, tag) => {
                onChange(parents, segment, tag)
              }}
                ops={ops}
              >
                {item.children && item.children.length > 0 && (
                  <div
                    className={'segment-multilevel-opener'}
                    onClick={() => {
                      selectChild(item)
                    }}
                  >
                    {displayChildrenCount(selected, item.children)}{' '}
                    <CIcon icon={cilChevronRight} size={'lg'} />
                  </div>
                )}
              </SegmentListItem>
            </React.Fragment> )}
          </>
        </CListGroup>
      </div>
      {selectedId.length > 0 && children && children.length > 0 && (
        <Level onCloseEnd={closeEnd} onCloseStart={closeStart} level={level + 1} parents={nextParents} selected={selected} items={children} onChange={onChange} ops={ops} />
      )}
    </>
  )
}

Level.propTypes = {
  level: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.any,
  onCloseStart: PropTypes.func,
  onCloseEnd: PropTypes.func,
}

export default Level
