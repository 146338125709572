import React from "react";
import CIcon from "@coreui/icons-react";
import {cilArrowBottom, cilArrowTop, cilSortAscending, cilSortDescending, cilSwapVertical} from "@coreui/icons";

const Sort = ({field, direction, onChange}) => {

  return <span style={{cursor: "pointer"}} onClick={() => onChange(field, direction)}>
    {direction === "asc" || direction() === "asc" && <CIcon icon={cilArrowTop} />}
    {direction === "desc" || direction() === "desc" && <CIcon icon={cilArrowBottom} />}
    {(direction === "" || direction() === "" || !direction) && <CIcon icon={cilSwapVertical} />}
  </span>


}

export default Sort
