import React, { useEffect, useState } from 'react'
import { CPopover } from '@coreui/react'

const EntryTagList = ({ tags = [] }) => {
  const [shortTags, setShortTags] = useState([])
  const [fullTags, setFullTags] = useState([])
  useEffect(() => {
    if (tags && Array.isArray(tags)) {
      setShortTags(tags.slice(0, 3))
      setFullTags(tags)
    }
  }, [tags])

  if (!fullTags) {
    return <></>
  }

  if (tags.length > 4) {
    return (
      <CPopover trigger={['hover', 'focus']} content={<>{tags?.join(', ')}</>}>
        <span style={{ cursor: 'pointer' }}>
          {shortTags.join(', ')}{' '}
          <span style={{ fontSize: '0.8rem' }}>(and {tags.length - 3} more)</span>
        </span>
      </CPopover>
    )
  }
  return <>{fullTags.join(', ')}</>
}

export default EntryTagList
