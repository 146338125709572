import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { DELETE_SEARCH } from '../../../../graphql/queries/search-manage'
import { CButton, CModal, CModalBody, CModalHeader } from '@coreui/react'

const DeleteSearch = ({ id, onComplete = async () => void(0) }) => {
  const [visible, setVisible] = useState(false)
  const [deleteSearch, { loading }] = useMutation(DELETE_SEARCH, {
    variables: { id },
      onCompleted() {
        setVisible(false)
        onComplete()
      }
  })
  return (
    <>
      <CModal size={'sm'} visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader>Confirm Delete?</CModalHeader>
        <CModalBody className={'d-flex justify-content-between'}>
          <CButton color={"success"} size={'sm'} onClick={() => deleteSearch()}>
            Confirm
          </CButton>
          <CButton color={"primary"} variant={"outline"} size={'sm'} onClick={() => setVisible(false)}>
            Cancel
          </CButton>
        </CModalBody>
      </CModal>
      <CButton onClick={() => setVisible(true)} color={'danger'} className={'ms-auto'}>
        Delete
      </CButton>
    </>
  )
}

export default DeleteSearch
