import React, { useEffect, useState } from 'react'
import {
  CAlert,
  CCard,
  CCardBody,
  CCardHeader,
  CCardText,
  CCardTitle,
  CTooltip,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons'

const CQCContacts = ({ cqcContacts = [] }) => {
  const [contacts, setContacts] = useState([])

  useEffect(() => {
    if (!cqcContacts) {
      setContacts([])
    } else {
      setContacts(cqcContacts.filter((c) => c.name && c.name.length > 0))
    }
  }, [cqcContacts])
  return (
    <CCard className={'h-100'}>
      <CCardHeader>
        <CCardTitle>
          <CTooltip
            content={
              'The named people below are responsible for the following areas of the business.'
            }
          >
            <span>
              CQC Positions <CIcon icon={cilInfo} size={'sm'} />
            </span>
          </CTooltip>
        </CCardTitle>
      </CCardHeader>
      <CCardBody>
        {!contacts ||
          (contacts.length === 0 && (
            <CAlert
              color={'primary'}
              className={'d-flex align-items-center'}
              style={{ fontSize: '0.6rem' }}
            >
              <CIcon icon={cilInfo} className="flex-shrink-0 me-2" width={24} height={24} />
              <div>
                No Contact Information is available for this location. Contact your Rare Account
                manager if you'd like to learn more
              </div>
            </CAlert>
          ))}
        <>
          {contacts.map((contact, id) => (
            <CCardText key={`cqc-contact-${id}`}>
              <b>{contact.name}</b> {contact?.activity}
            </CCardText>
          ))}
        </>
      </CCardBody>
    </CCard>
  )
}

export default CQCContacts
