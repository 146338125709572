import React, { useCallback, useEffect, useState } from 'react'
import {
  CBadge,
  CCol,
  CPlaceholder,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { Link } from 'react-router-dom'
import { CSmartPagination } from '@coreui/react-pro'
import EntryTagList from './Components/EntryTagList'
import Sort from '../SearchBar/Components/Sort'
import CompanySize from './Components/CompanySize'
import ClinicLists from '../MyLists/Components/ClinicLists'
import NameSearch from '../SearchBar/Components/NameSearch'

const ClinicSearch = ({
  showLink = true,
  showAddToSearch = false,
  filter = [],
  sort = [],
  entries = [],
  loading = true,
  totalCount = 0,
  page = 0,
  pageSize = 0,
  onFilterChange = (filters) => void 0,
  onPageChange = (page) => void 0,
  onPageSizeChange = (pageSize) => void 0,
  onSortChange = (field, direction) => void 0,
  refetch = async () => void 0,
}) => {
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    if (totalCount > 0) {
      setTotalPages(Math.ceil(totalCount / pageSize))
    } else {
      setTotalPages(0)
    }
  }, [totalCount, pageSize])

  const setPage = useCallback(
    (p) => {
      if (!loading && totalPages > 0) {
        onPageChange(p)
      }
    },
    [page, onPageChange, totalPages, loading],
  )

  useEffect(() => {
    onPageSizeChange(10)
  }, [])

  const columns = [
    { key: 'name', label: 'Location Name' },
    // {key:}
  ]

  return (
    <div>
      <CRow>
        <div className="text-end fw-light fs-6">
          Total Results: {totalCount}
          {totalCount >= 10000 && '+'}
        </div>
      </CRow>
      <CRow>
        <CCol>
          <CSmartPagination
            pages={totalPages}
            activePage={page}
            onActivePageChange={setPage}
            align={'center'}
          />
        </CCol>
      </CRow>

      <CTable align="middle" className="border" hover responsive small>
        <CTableHead color="light" className={'position-relative'}>
          <CTableRow>
            <CTableHeaderCell>
              Location Name{' '}
              <Sort
                field={'name'}
                direction={() => {
                  const so = sort.find((s) => s.field === 'name')
                  if (so) {
                    return so.direction
                  } else {
                    return ''
                  }
                }}
                onChange={onSortChange}
              />
            </CTableHeaderCell>
            <CTableHeaderCell>Size</CTableHeaderCell>
            <CTableHeaderCell>Brands</CTableHeaderCell>
            <CTableHeaderCell>Treatment Types</CTableHeaderCell>
          </CTableRow>
          <CTableRow>
            <CTableDataCell style={{ width: '30%' }}>
              <NameSearch />
            </CTableDataCell>
            <CTableDataCell colSpan={3}></CTableDataCell>
          </CTableRow>
        </CTableHead>

        <CTableBody className={'position-relative'}>
          <>
            {!loading && totalPages === 0 && (
              <tr>
                <td colSpan={4} className={'p-2 text-center fst-italic'}>
                  No Clinics Found
                </td>
              </tr>
            )}
            {loading &&
              [...new Array(10)].map((i, id) => (
                <React.Fragment key={`loading-${id}`}>
                  <CTableRow>
                    <CPlaceholder component={CTableDataCell} className={'w-25'} animation={'wave'}>
                      <CPlaceholder xs={9} />
                      <br />
                      <CPlaceholder xs={5} size={'sm'} />
                    </CPlaceholder>
                    <CPlaceholder component={CTableDataCell} className={'w-25'} animation={'wave'}>
                      <CPlaceholder xs={2} />
                    </CPlaceholder>
                    <CPlaceholder
                      component={CTableDataCell}
                      style={{ width: '33%' }}
                      animation={'wave'}
                    >
                      <CPlaceholder xs={1} size={'sm'} /> <CPlaceholder xs={2} size={'sm'} />{' '}
                      <CPlaceholder xs={1} size={'sm'} /> <CPlaceholder xs={1} size={'sm'} />{' '}
                      <CPlaceholder xs={2} size={'sm'} /> <CPlaceholder xs={2} size={'sm'} />{' '}
                      <CPlaceholder xs={1} size={'sm'} /> <CPlaceholder xs={1} size={'sm'} />{' '}
                      <CPlaceholder xs={2} size={'sm'} /> <CPlaceholder xs={1} size={'sm'} />{' '}
                    </CPlaceholder>
                    <CPlaceholder
                      component={CTableDataCell}
                      style={{ width: '33%' }}
                      animation={'wave'}
                    >
                      <CPlaceholder xs={1} size={'sm'} /> <CPlaceholder xs={1} size={'sm'} />{' '}
                      <CPlaceholder xs={2} size={'sm'} /> <CPlaceholder xs={1} size={'sm'} />{' '}
                      <CPlaceholder xs={1} size={'sm'} /> <CPlaceholder xs={2} size={'sm'} />{' '}
                      <CPlaceholder xs={2} size={'sm'} /> <CPlaceholder xs={1} size={'sm'} />{' '}
                      <CPlaceholder xs={1} size={'sm'} /> <CPlaceholder xs={2} size={'sm'} />{' '}
                    </CPlaceholder>
                  </CTableRow>
                </React.Fragment>
              ))}
          </>

          <>
            {!loading &&
              entries.map((item, index) => (
                <CTableRow
                  key={`${item.external_id}-${index}`}
                  className={`clinic-list-row${item.is_new ? ' new' : ''}`}
                >
                  <CTableDataCell>
                    <div>
                      {showLink && <Link to={'/clinic/' + item.external_id}>{item.name}</Link>}
                      {!showLink && item.name}{' '}
                      {item.is_new && (
                        <CBadge size={'sm'} color={'info'}>
                          New
                        </CBadge>
                      )}
                    </div>
                    <div className="small text-medium-emphasis">
                      {item.address && item.address.line1} &nbsp;{' '}
                      {item.address && item.address.postal_code}
                    </div>
                    {showAddToSearch && (
                      <ClinicLists
                        lists={item.lists}
                        clinicId={item.external_id}
                        onChange={async () => refetch()}
                      />
                    )}
                  </CTableDataCell>
                  <CTableDataCell>
                    <CompanySize size={item.company_size} />
                  </CTableDataCell>
                  <CTableDataCell style={{ width: '33%' }}>
                    {/* WARNING!!!! FILTERING OUT test classifciation 'pnp-test' */}
                    <EntryTagList
                      tags={item.brands?.filter((c) => !c.includes('pnp-test')) ?? []}
                    />
                  </CTableDataCell>
                  <CTableDataCell style={{ width: '33%' }}>
                    {/* WARNING!!!! FILTERING OUT test classifciation 'pnp1' & 'pnp2' */}
                    <EntryTagList
                      tags={
                        item.treatment_categories?.filter((trmt) => !trmt.includes('pnp')) ?? []
                      }
                    />
                  </CTableDataCell>
                </CTableRow>
              ))}
          </>
        </CTableBody>
      </CTable>

      <CRow>
        <CSmartPagination
          pages={totalPages}
          activePage={page}
          onActivePageChange={setPage}
          align={'center'}
        />
      </CRow>
    </div>
  )
}

export default ClinicSearch
