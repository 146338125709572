import React, {useState} from "react";
import {CButton, CPopover} from "@coreui/react";
import {useMutation} from "@apollo/client";
import {DELETE_LOCATION_LIST} from "../../../graphql/queries/my-lists";

const DeleteList = ({id, onDelete = async () => void(0)}) => {
  const [visible, setVisible] = useState(false)
  const [deleteList] = useMutation(
    DELETE_LOCATION_LIST,
    {
      variables: {
        id,
      },
      onCompleted() {
        onDelete()
          .then(() => setVisible(false))
      }
    }
  )
  return <CPopover
    trigger={[]}
    visible={visible}
    title={`Delete List?`}
    content={<>
      <p>This will permanently delete the list.</p>
      <div><CButton color={"primary"} size={"sm"} onClick={() => {
        deleteList()
      }}>OK</CButton> <CButton color={"primary"} size={"sm"} onClick={() => setVisible(false)}>Cancel</CButton></div>
    </>}><CButton color={"primary"} onClick={() => setVisible(true)}>Delete</CButton></CPopover>
}

export default DeleteList
