import React from 'react'
import { Field } from 'rc-field-form'
import { CCol, CFormLabel, CRow } from '@coreui/react'

const Error = ({ children, warning }) => (
  <ul style={{ color: warning ? 'orange' : 'red' }}>
    {children.map((error, index) => (
      <li key={index}>{error}</li>
    ))}
  </ul>
)

const FieldState = ({ touched, validating }) => (
  <div
    style={{
      color: 'green',
      // position: 'absolute',
      // marginTop: -35,
      left: 300,
    }}
  >
    {touched ? <span>Touched!</span> : null}
    {validating ? <span>Validating!</span> : null}
  </div>
)

const FormField = ({ name, label, children, horizontal = false, ...props }) => {
  return (
    <Field name={name} {...props}>
      {(control, meta, form) => {
        let newChildren = <></>
        if (typeof children === 'function') {
          newChildren = children(control, meta, form)
        } else {
          newChildren = React.Children.map(children, (child) => {
            if (child.props.__FormItem) {
              let valid = false
              let invalid = false
              let feedback = undefined
              if (meta.errors.length > 0) {
                invalid = true
                feedback = meta.errors.join(', ')
              }
              return React.cloneElement(child, { ...control, valid, invalid, feedback, form })
            } else {
              return child
            }
          })
        }

        return (
          <>
            <CRow className={'mb-3'}>
              <CFormLabel
                className={`col-form-label${horizontal ? ' col-xs-12' : ' col-sm-2'}`}
                htmlFor={name}
              >
                {label}
              </CFormLabel>
              <CCol>{newChildren}</CCol>
            </CRow>
          </>
        )
      }}
    </Field>
  )
}

export default FormField
