import React from 'react'
// WHY DOES THIS EXIST?!?
const CompanySize = ({ size }) => {
  let tag = 'Unknown'
  switch (size) {
    case 'large': {
      tag = 'Large'
      break
    }
    case 'medium': {
      tag = 'Medium'
      break
    }
    case 'medium/large': {
      tag = 'Medium/Large'
      break
    }
    case 'small': {
      tag = 'Small'
      break
    }
    case 'micro': {
      tag = 'Micro'
      break
    }
  }
  return <span style={{ cursor: 'default', userSelect: 'none' }}>{tag}</span>
}

export default CompanySize
