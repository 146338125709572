import {useEffect} from "react";
import eventBus from "../tools/event-bus";


// type Types = {type: string}
// type EventBus = <T>(event: string, callback?: (e: CustomEvent) => void) => {cancel: () => void, emit: (arg: T & Types) => void}

export const useEventBus = (event, callback = (e) => void 0) => {

  const cancel = () => eventBus.cancel(event, callback)
  const create = () => eventBus.create(event, callback)
  const emit = (data) => eventBus.emit(event, data)

  useEffect(() => {
    if(!!event) {
      create()
      return () => cancel()
    } else {
      return () => void(0)
    }
  }, [event, callback])

  return {
    cancel,
    emitToast: emit,
    emit,
  }
}
