import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'

const MessageModal = ({ message, title, onOk = () => void 0, onCancel, onComplete = () => void 0 }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (title && title.length > 0) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [message, title, visible])

  return (
    <>
      <CModal visible={visible} onClose={() => {
        setVisible(false)
        onOk()
        onComplete()
      }}>
        <CModalHeader>{title && <CModalTitle
        >{title}</CModalTitle>}</CModalHeader>
        {message && <CModalBody>{message}</CModalBody>}
        <CModalFooter>
          {onCancel && (
            <CButton
              color={"primary"}
              onClick={() => {
                setVisible(false)
                setTimeout(() => {
                  onComplete()
                }, 300)
                onCancel()
              }}
            >
              Cancel
            </CButton>
          )}
          <CButton
            color={"primary"}
            onClick={() => {
              setVisible(false)
              onOk()
              setTimeout(() => {
                onComplete()
              }, 300)
            }}
          >
            OK
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

MessageModal.propTypes = {
  message: PropTypes.any,
  title: PropTypes.any,
  onOk: PropTypes.func,
  onCancel: PropTypes.func
}

export default MessageModal
