import React, { useCallback, useEffect, useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { LIST_SUB_USERS } from '../../../graphql/queries/user-manage'
import { CAlert, CButton, CCard, CCardBody, CCardHeader, CCardTitle } from '@coreui/react'
import { CSmartTable } from '@coreui/react-pro'
import { Link } from 'react-router-dom'
import DeleteUser from './Components/DeleteUser'
import SetUserSearch from './Components/SetUserSearch'
import { permissionsAllow } from '../../../tools/permissions'
import ChangePassword from './Components/ChangePassword'
import ShowUserRole from './Components/ShowUserRole'
import { account } from '../../../graphql/policies/auth'

const Users = () => {
  const myAccount = useReactiveVar(account)

  const [accountId, setAccountId] = useState('')

  useEffect(() => {
    if (myAccount && Object.keys(myAccount).length > 0) {
      setAccountId(myAccount.id)
    }
  }, [myAccount])

  const [tableEntries, setTableEntries] = useState([])
  const {
    data: { subscription: { seats }, ListSubUsers: { entries, totalCount } } = {
      subscription: { seats: 1 },
      ListSubUsers: { entries: [], totalCount: 0 },
    },
    refetch,
    loading,
  } = useQuery(LIST_SUB_USERS, {
    onCompleted({ ListSubUsers: { entries = [] } }) {},
  })

  const totalExceeded = useCallback(
    () => seats > 0 && totalCount >= seats,
    [totalCount, entries, seats],
  )

  useEffect(() => {
    if (!loading) {
      setTableEntries(
        entries.map((entry) => {
          return {
            ...entry,
          }
        }),
      )
    }
  }, [loading, entries])

  const columns = [
    {
      key: 'name',
    },
    {
      key: 'email',
    },
    // {
    //   key: 'view_subscription',
    //   label: (
    //     <>
    //       View Subscription{' '}
    //       <CTooltip content={'When checked, the user can see the full subscription and is not restricted to a defined sub-search.'}>
    //         <CIcon icon={cilInfo} width={14} />
    //       </CTooltip>
    //     </>
    //   ),
    // },
    {
      key: 'superuser',
      label: (
        <>
          User Role{' '}
          {/*<CTooltip content={'Gives users the ability to manage other users in your subscription.'}>*/}
          {/*  <CIcon icon={cilInfo} width={14} />*/}
          {/*</CTooltip>*/}
        </>
      ),
    },
    {
      key: 'options',
      label: 'Options',
      _style: {
        width: '25rem',
      },
    },
  ]
  return (
    <>
      <CCard>
        <CCardHeader>
          <CCardTitle>Manage Users</CCardTitle>
        </CCardHeader>
        <CCardBody>
          {!totalExceeded() && (
            <Link to={'create'} className={'btn btn-primary'}>
              Create New User
            </Link>
          )}
          {totalExceeded() && (
            <CButton color={'primary'} disabled>
              Create New User
            </CButton>
          )}

          {totalExceeded() && (
            <CAlert color={'info'} className={'mt-2'}>
              You have reached your seat limit of {seats} and cannot create any more users. Please
              speak to your Rare Account Manager if you wish to increase your seat count.
            </CAlert>
          )}

          <CSmartTable
            className={'mt-3'}
            columns={columns}
            items={tableEntries}
            selectable={false}
            scopedColumns={{
              name: (item) => (
                <td>
                  <Link to={`edit/${item.id}`}>{item.name}</Link>
                </td>
              ),
              options: (item) => (
                <>
                  <td align={'right'}>
                    {permissionsAllow(item.permissions, ['view_subscription_search']) ||
                      (!permissionsAllow(item.permissions, [
                        'sub_user_manage',
                        'sub_search_manage',
                      ]) && <SetUserSearch user={item} onComplete={() => refetch()} />)}{' '}
                    <ChangePassword userId={item.id} />{' '}
                    <DeleteUser
                      disabled={accountId === item.id}
                      userId={item.id}
                      onComplete={() => refetch()}
                    />
                  </td>
                </>
              ),
              // view_subscription: (item) => (<td>
              //     <SetUserRole user={item} roles={{allow:["view_subscription_search"], deny: ["view_subscription_search", "sub_search_manage", "sub_search_view", "sub_user_manage", "sub_user_view"]}} onComplete={() => refetch()} />
              // </td>),
              superuser: (item) => (
                <td>
                  <ShowUserRole user={item} />
                  {/*<SetUserRole user={item} roles={{allow: ["view_subscription_search", "sub_search_manage", "sub_search_view", "sub_user_manage", "sub_user_view"], deny: ["view_subscription_search","sub_search_manage", "sub_search_view", "sub_user_manage", "sub_user_view"]}} onComplete={() => refetch()} />*/}
                </td>
              ),
            }}
          />
        </CCardBody>
      </CCard>
    </>
  )
}

export default Users
