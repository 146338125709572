import React, {useEffect, useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {
  CButton,
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
  CNavLink,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {cilAccountLogout, cilMenu} from '@coreui/icons'

import {AppBreadcrumb} from './index'
import {AUTH_STATE, authState} from "../graphql/policies/auth";
import {clearAuthToken} from "../auth";
import {useApolloClient} from "@apollo/client";


const AppHeader = ({collapse}) => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const client = useApolloClient()

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          {/* <CIcon icon={logo} height={48} alt="Logo" /> */}
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/" component={NavLink}>
            Rare:Monitor Dashboard
            </CNavLink>
          </CNavItem>

          {/*<CNavItem>*/}
          {/*  <CNavLink href="#">Users</CNavLink>*/}
          {/*</CNavItem>*/}
          {/*<CNavItem>*/}
          {/*  <CNavLink href="#">Settings</CNavLink>*/}
          {/*</CNavItem>*/}
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
            <CButton
              onClick={() => {
                clearAuthToken()
                authState(AUTH_STATE.LOGGING_OUT)
              }}
              color={"primary"}
              variant={"ghost"}>
              <CIcon icon={cilAccountLogout} size={"lg"} title={"Log Out"}/>
              <span className={"visually-hidden"}>Log Out</span>
            </CButton>

          </CNavItem>
        </CHeaderNav>
        {/*<CHeaderNav>*/}
        {/*  <CNavItem>*/}
        {/*    <CNavLink href="#">*/}
        {/*      <CIcon icon={cilBell} size="lg" />*/}
        {/*    </CNavLink>*/}
        {/*  </CNavItem>*/}
        {/*  <CNavItem>*/}
        {/*    <CNavLink href="#">*/}
        {/*      <CIcon icon={cilList} size="lg" />*/}
        {/*    </CNavLink>*/}
        {/*  </CNavItem>*/}
        {/*  <CNavItem>*/}
        {/*    <CNavLink href="#">*/}
        {/*      <CIcon icon={cilEnvelopeOpen} size="lg" />*/}
        {/*    </CNavLink>*/}
        {/*  </CNavItem>*/}
        {/*</CHeaderNav>*/}
        {/*<CHeaderNav className="ms-3">*/}
        {/*  <AppHeaderDropdown />*/}
        {/*</CHeaderNav>*/}
      </CContainer>
      <CHeaderDivider />
      {!collapse &&  <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>}
    </CHeader>
  )
}

export default AppHeader
