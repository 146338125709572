import React, { useEffect, useState } from 'react'
import CIcon from '@coreui/icons-react'
import { cilLink } from '@coreui/icons'
import { Link } from 'react-router-dom'
import { EnvelopeAt, Facebook, Instagram, Link45deg } from 'react-bootstrap-icons'

const filter = ['email']

const icons = {
  facebook: Facebook,
  instagram: Instagram,
}

const SocialData = ({socialData = []}) => {
  const [filteredData, setFilteredData] = useState([])
  useEffect(() => {
    if(!socialData) {
      setFilteredData([])
    } else {
      setFilteredData(socialData.filter((d) => !filter.includes(d.type.toLowerCase())))
    }
  }, [socialData]);

  if(filteredData.length === 0) {
    return <></>
  }
  return <>
    <h6 className={"fw-bold"}>Social Media</h6>
    <div className={"hstack gap-2"}>
      <>{filteredData.map((d) => {
        const BIcon = icons[d.type] ?? Link45deg
        return <React.Fragment key={d.url}>
        <Link to={d.url} target={"_blank"} title={d.type}>
          <BIcon size={"1.6rem"} />
          <CIcon icon={icons[d.type] ?? cilLink} size={"xl"} />
        </Link>
      </React.Fragment>
      })}</>
    </div>
  </>
}

export default SocialData
