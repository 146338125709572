import React, { useEffect, useState } from 'react'
import SearchableCatalogue from '../SearchableCatalogue'
import { MARKET_CLASSIFICATION, TREATMENT_CATEGORY } from '../../glossary-terms'
import { useQuery } from '@apollo/client'
import { MY_ACCOUNT } from '../../../../graphql/queries/my_account'
import { CCallout, CSpinner } from '@coreui/react'
import {includes} from "core-js/internals/array-includes";

const compiledData = (terms = '') => {
  return TREATMENT_CATEGORY.map((treatment) => {
    const market_classification = MARKET_CLASSIFICATION.find(
      (mc) => mc.id === treatment.market_classification,
    )

    if (market_classification) {
      treatment = { ...treatment, market_classification: market_classification.name }
    }
    return treatment
  })
}

const Treatments = () => {
  const [subscription, setSubscription] = useState(undefined)

  const [treatmentCategories, setTreatmentCategories] = useState([])
  const [marketClassifications, setMarketClassifications] = useState([])

  const { loading, error } = useQuery(MY_ACCOUNT, {
    onCompleted({ myAccount: { subscription, user } }) {
      setSubscription(subscription)
      const { searches } = subscription
      const tc = searches.flatMap((search) => search.treatment_categories)
      const mc = searches.flatMap((search) => search.market_classification)
      setTreatmentCategories(Array.from(new Set(tc)))
      setMarketClassifications(Array.from(new Set(mc)))
    },
  })

  const [terms, setTerms] = useState('')
  const [data, setData] = useState([])

  const columns = [
    { key: 'name', label: 'Treatment Category', _style: { width: '15rem' } },
    {
      key: 'market_classification',
      label: 'Rare: Market Classification',
      _style: { width: '15rem' },
    },
    { key: 'treatment_terms', label: 'Treatment Terms' },
  ]

  useEffect(() => {
    if (loading || !subscription) {
      setData([])
    } else {
      let finalData = compiledData()
        // .map((treatment) => {
        //   const treatment_terms = treatment.treatment_terms.filter((term) => {
        //     if (treatmentCategories.length === 0) return true
        //     return (
        //       treatmentCategories.includes(term) ||
        //       treatmentCategories.filter((t) => t.toLowerCase() === term.toLowerCase()).length > 0
        //     )
        //   })
        //   return { ...treatment, treatment_terms }
        // })
        // .filter((treatment) => {
        //   if (marketClassifications.length === 0) return true
        //   return (
        //     marketClassifications.filter(
        //       (mc) => mc.toLowerCase() === treatment.market_classification.toLowerCase(),
        //     ).length > 0
        //   )
        // })

      if (terms.length > 0) {
        finalData = finalData.filter((treatment) => {
          const filtered = treatment.treatment_terms.filter((term) =>
            term.toLowerCase().includes(terms.toLowerCase()),
          )

          return filtered.length > 0 || treatment.market_classification.toLowerCase().includes(terms.toLowerCase())  || treatment.name.toLowerCase().includes(terms.toLowerCase())
        })
      }

      setData(
        finalData.map((treatment) => ({
          ...treatment,
          treatment_terms: treatment.treatment_terms.join(', '),
        })),
      )
    }
  }, [terms, treatmentCategories, subscription, marketClassifications])

  if (loading || !subscription) {
    return (
      <>
        <CSpinner />
      </>
    )
  }

  return (
    <>
      <h4 className={'mb-2'}>Treatment Catalogue</h4>
      <p>This is the current standard classification for how Rare roll-up certain treatments into treatment categories.</p>
      <CCallout color={'primary'} className={'bg-white'}>
        If you have any feedback or queries on the terms below then please get in touch with your
        Account Manager.
      </CCallout>
      <SearchableCatalogue data={data} columns={columns} onSearch={setTerms} />
    </>
  )
}

export default Treatments
