import {gql} from "@apollo/client";

export const READ_SEARCHES = gql`
  query ReadSearches {
    ListUserSearch {
      entries {
        id name userCount
        content
      }
    }
  }
`

export const CREATE_SEARCH = gql`
    mutation CreateUserSearch($name: String! $input: JSONObject!) {
        CreateUserSearch(name: $name input: $input ) {
            id name userCount
            content
        }
    }
`

export const UPDATE_SEARCH = gql`
    mutation UpdateUserSearch($id: String! $name: String $input: JSONObject) {
        UpdateUserSearch(id: $id name: $name input: $input) {
            id name
            content
        }
    }
`

export const READ_SEARCH_USERS = gql`
  query ReadSearch($searchId: String!) {
      ListSearchUsers(searchId: $searchId) {
          entries {
              id name email
          }
      }
      ListSubUsers {
          entries {
              name id email
          }
      }
  }
`

export const DELETE_SEARCH = gql`
    mutation DeleteSeach($id: String!) {
        DeleteUserSearch(id: $id)
    }
`

export const REACH_USER_SEARCH = gql`
  query ReadSearch($id: String!) {
    ReadUserSearch(id: $id) {
      id name userCount
      content
    }
  }
`

export const READ_SEGMENTS = gql`
  query ReadSegments($segments: [String!] = []) {
    SearchSegmentData(segments: $segments)
  }
`

export const ASSIGN_SUB_USER_SEARCH = gql`
  mutation AssignSubUserSearch($subUserId: [String!]! $removeId: [String!]! $searchId: String!) {
    AssignSubUserSearch(subUserId: $subUserId searchId: $searchId)
    RemoveSubUserSearch(searchId: $searchId subUserId: $removeId)
  }
`

