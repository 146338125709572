import React, { useCallback, useEffect, useState } from 'react'
import { CFormInput } from '@coreui/react'
import useSearch from '../../../hooks/use-search'
import debounce from 'lodash.debounce'
import CIcon from '@coreui/icons-react'
import { cilXCircle } from '@coreui/icons'

const NameSearch = () => {
  const [searchText, setSearchText] = useState('')
  const { search, replaceParam } = useSearch()

  const filterName = useCallback(
    debounce((text) => {
      replaceParam('freeform', text)
    }, 500),
    [],
  )

  useEffect(() => {
    if (search && search.hasOwnProperty('freeform')) {
      setSearchText(search.freeform ?? '')
    } else {
      setSearchText('')
    }
  }, [search])

  return (
    <div style={{ position: 'relative' }}>
      <CFormInput
        placeholder={'Filter location name'}
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value)
          filterName(e.target.value)
        }}
        size={'sm'}
      />
      {searchText.length > 0 && <span
        style={{
          position: 'absolute',
          right: '0.5rem',
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
        }}
        onClick={() => filterName('')}
        title={'Clear filter'}
      >
        <CIcon icon={cilXCircle} />{' '}
      </span>}
    </div>
  )
}

export default NameSearch
