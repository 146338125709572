import React, { useCallback, useEffect, useState } from 'react'
import { CButton, CFormInput, CInputGroup, CPopover } from '@coreui/react'
import { cilPencil } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

const EditName = ({ name, onComplete = async (name) => void 0 }) => {
  const [edit, setEdit] = useState(false)
  const [newVal, setNewVal] = useState(name)

  const complete = useCallback(() => {
    onComplete(newVal).then(() => setEdit(false))
  }, [newVal])

  useEffect(() => {
    setNewVal(name)
  }, [name])
  if (edit) {
    return (
      <span>
        <CInputGroup>
          <CFormInput
            value={newVal}
            onChange={(e) => setNewVal(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                complete()
              }
              if (e.key === 'Escape') {
                setNewVal(name)
                setEdit(false)
              }
            }}
          />
          <CButton
            color={"primary"}
            onClick={() => {
              complete()
            }}
          >
            Save
          </CButton>
        </CInputGroup>
      </span>
    )
  }
  return (
    <span>
      {name}{' '}
      <CPopover trigger={'hover'} content={'Edit Name'}>
        <CIcon icon={cilPencil} style={{ cursor: 'pointer' }} onClick={() => setEdit(true)} />
      </CPopover>
    </span>
  )
}

export default EditName
