import React, { useEffect, useState } from 'react'
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react'
import Lists from '../AddLocationsToList/Lists'
import NewList from '../AddLocationsToList/NewList'
import LimitInformation from '../AddLocationsToList/LimitInformation'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons'

const ListSelect = ({
  locations = [],
  onComplete = async () => void 0,
  onError = (e) => void 0,
  onlyAdd = false,
  showModal = false
}) => {
  const [activeTab, setActiveTab] = useState(1)

  useEffect(() => {
    return () => setActiveTab(1)
  }, [])

  return (
    <>
      <CNav variant={'tabs'} role={'tablist'}>
        <CNavItem role={'presentation'}>
          <CNavLink
            active={activeTab === 1}
            component={'button'}
            onClick={() => setActiveTab(1)}
            aria-controls={'current-lists'}
            aria-selected={activeTab === 1}
          >
            Current Lists
          </CNavLink>
        </CNavItem>
        <CNavItem role={'presentation'}>
          <CNavLink
            active={activeTab === 2}
            component={'button'}
            onClick={() => setActiveTab(2)}
            aria-controls={'new-list'}
            aria-selected={activeTab === 2}
          >
            New List
          </CNavLink>
        </CNavItem>
        <CNavItem role={'presentation'}>
          <CNavLink
            active={activeTab === 3}
            component={'button'}
            onClick={() => setActiveTab(3)}
            aria-controls={'limit-information'}
            aria-selected={activeTab === 3}
          >
            <CIcon icon={cilInfo} size={'sm'} /> <strong>Lists Information</strong>
          </CNavLink>
        </CNavItem>
      </CNav>
      <CTabContent>
        <CTabPane role={'tabpanel'} aria-labelledby={'current-lists'} visible={activeTab === 1}>
          <Lists
            locations={locations}
            onComplete={onComplete}
            onError={onError}
            onlyAdd={onlyAdd}
            showModal={showModal}
          />
        </CTabPane>
        <CTabPane role={'tabpanel'} aria-labelledby={'new-list'} visible={activeTab === 2}>
          <NewList locations={locations} onComplete={onComplete} showModal={showModal} />{' '}
        </CTabPane>
        <CTabPane
          role={'tabpanel'}
          aria-labelledby={'limit-information'}
          visible={activeTab === 3}
          className={'mt-3'}

        >
          <LimitInformation />
        </CTabPane>
      </CTabContent>
    </>
  )
}

export default ListSelect
