import React, { useState } from 'react'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import ListSelect from '../ListSelect'

const AddLocationsToList = ({ locations = [], onComplete = async () => void 0, ...props }) => {
  const [visible, setVisible] = useState(false)
  let children
  let only

  try {
    only = React.Children.only(props.children)
    if (only) {
      children = React.cloneElement(only, { onClick: () => setVisible(true) })
    }
  } catch (e) {
    if (!only) {
      children = <span onClick={() => setVisible(true)}>{props.children}</span>
    }
  }
  return (
    <>
      {children}
      <CModal
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
        unmountOnClose
      >
        <CModalHeader>
          <CModalTitle>Add Clinic to List</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <ListSelect
            locations={locations}
            onComplete={() => {
              onComplete()
              // .then(() => setVisible(false))
            }}
          />
        </CModalBody>
        <CModalFooter>
          <CButton color={"primary"} onClick={() => setVisible(false)}>Done</CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default AddLocationsToList
