import React, { useState } from 'react'
import CIcon from '@coreui/icons-react'
import { useParams } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'
import { cilExternalLink, cilHospital, cilInfo, cilPhone } from '@coreui/icons'

import {
  CAlert,
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCardText,
  CCardTitle,
  CCol,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow,
  CTooltip,
} from '@coreui/react'

import { useQuery } from '@apollo/client'
import RestrictedListPanel from './restricted-list-panel'
import ClinicCompetition from './local-competition'
import CompetitorTreatments from './competitor-treatments'
import defaultSearch from '../../tools/default-search'
import { FETCH_CLINIC } from '../../graphql/queries/clinic'
import ClinicLists from '../../components/MyLists/Components/ClinicLists'
import ClinicMap from '../../components/ClinicDetail/Components/ClinicMap'
import SocialData from '../../components/ClinicDetail/Components/SocialData'
import FeatureFlagged from '../../components/Utility/FeatureFlagged'
import CQCContacts from "../../components/ClinicDetail/Components/CQCContacts";
import HCPContacts from "../../components/ClinicDetail/Components/HCPContacts";
import EmailDisplay from "../../components/ClinicDetail/Components/EmailDisplay";

const ClinicDetail = (param) => {
  let { locationId: externalId } = useParams()
  const [search, setSearch] = useState(defaultSearch)

  const {
    loading,
    error,
    refetch,
    data,
    data: { clinic: { lists, is_new, hcp_contacts = [], social_data } } = {
      clinic: { lists: [], is_new: false, social_data: [], hcp_contacts: [] },
    },
  } = useQuery(FETCH_CLINIC, {
    variables: {
      externalId: externalId,
    },
    onCompleted({ subscription }) {
      if (subscription) {
        if (subscription.searches && subscription.searches.length > 0) {
          setSearch(subscription.searches[0])
        } else {
          setSearch(defaultSearch)
        }
      } else {
        setSearch(defaultSearch)
      }
    },
  })

  if (loading) return <CSpinner />

  if (error)
    return (
      <CAlert color="danger">
        <p>Error : {error.message}</p>
        <p>Please contact your Rare Account manager for assistance.</p>
      </CAlert>
    )

  const normaliseWebsite = (website) => {
    if (!website || website.length === 0) {
      return ''
    }

    if (!website.includes(':')) {
      // let's try and mangle it back into shape
      website = website.replace(/^http/, '')
      website = website.replace(/^\/+/, '')
      website = `http://${website}`
    }

    try {
      const newSite = new URL(website)
      return `${newSite.protocol}//${newSite.hostname}${newSite.pathname ?? '/'}`
    } catch (e) {
      return ''
    }
  }

  return (
    <>
      <div className={'d-flex'}>
        <h3 className={'mb-2 d-inline-block'} style={{ marginLeft: '0.5rem' }}>
          <CIcon icon={cilHospital} size={'xl'} /> {data.clinic.name}{' '}
        </h3>
        {is_new && (
          <span className={'d-block'} style={{ padding: '0.3rem 0 0 0.3rem' }}>
            <CBadge color={'primary'} size={'sm'}>
              New
            </CBadge>
          </span>
        )}
      </div>
      <ClinicLists lists={lists} clinicId={externalId} onChange={async () => refetch()} />

      <h4 className={'ms-1'}>Location overview</h4>
      <hr />
      <CRow xs={{ gutter: 1 }} className={'mb-1'}>
        <CCol sm={6} xs={12} md={4}>
          <CCard className={'h-100'}>
            <CCardHeader>
              <CCardTitle>Contact Details</CCardTitle>
            </CCardHeader>
            <CCardBody>
              <h5 className={'fw-bold'}>Trading Address</h5>
              <CCardText component={'address'}>
                {data.clinic.address.line1} <br></br>
                {data.clinic.address.line2 && (
                  <>
                    {data.clinic.address.line2}
                    <br></br>
                  </>
                )}
                {data.clinic.address.locality} <br></br>
                {data.clinic.address.region} <br></br>
                {data.clinic.address.postal_code} <br></br>
              </CCardText>

              <CCardText>
                <CIcon icon={cilPhone} /> {data.clinic.phone_number}
              </CCardText>

              <CCardText>
                <CIcon icon={cilExternalLink} />{' '}
                <a href={normaliseWebsite(data.clinic.website)} target="_blank">
                  {normaliseWebsite(data.clinic.website)}
                </a>
              </CCardText>
                <SocialData socialData={data.clinic?.social_data} />
                <EmailDisplay socialData={data.clinic?.social_data} />
            </CCardBody>
          </CCard>
        </CCol>

        <CCol sm={6} xs={12} md={4}>
          <CCard className={'h-100'}>
            <CCardBody>
              <ClinicMap location={data.clinic.location ?? {}} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <h4 className={'mt-4 ms-1'}>
        <span>Contacts</span>
      </h4>
      <hr />

      <CRow xs={{ gutter: 1 }} className={'mb-1'}>
        {data.clinic.contacts && data.clinic.contacts.filter((c) => c.name && c.name.length > 0).length > 0 &&
        <CCol sm={6} xs={12} md={4}>
          <CQCContacts cqcContacts={data.clinic.contacts} />
        </CCol>}
        <FeatureFlagged flag={"hcp-contacts"}>
        <CCol sm={6} xs={12} md={4}>
          <HCPContacts hcpContacts={hcp_contacts} />
        </CCol>
        </FeatureFlagged>
      </CRow>

      <h4 className={'mt-4 ms-1'}>
        <span>Marketing and Finance</span>
      </h4>

      <hr />

      <CRow xs={{ gutter: 1 }} className={'mb-1'}>
        <CCol sm={6} xs={12} md={4}>
          <CCard className={'h-100'}>
            <CCardHeader>
              <CCardTitle>
                <CTooltip
                  content={
                    'Below is the latest published financial data we have for this business, from Companies House.'
                  }
                >
                  <span>
                    Financial Data <CIcon icon={cilInfo} size={'sm'} />
                  </span>
                </CTooltip>
              </CCardTitle>
            </CCardHeader>
            <CCardBody>
              {data.clinic.companies_house_number && (
                <CTable borderless small>
                  <CTableRow>
                    <CTableHeaderCell>Companies House #</CTableHeaderCell>
                    <CTableDataCell>{data.clinic.companies_house_number}</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell>Registered Business Name</CTableHeaderCell>
                    <CTableDataCell>{data.clinic.legal_name}</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell>Trading Name</CTableHeaderCell>
                    <CTableDataCell>{data.clinic.name}</CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell>Business Size</CTableHeaderCell>
                    <CTableDataCell>
                      Rare estimates that this is a{' '}
                      <span style={{ fontStyle: 'italic', padding: 0 }}>
                        {data.clinic.company_size}
                      </span>{' '}
                      company.
                    </CTableDataCell>
                  </CTableRow>
                  <CTableRow>
                    <CTableHeaderCell />
                    <CTableDataCell>
                      {data.clinic.company_financials.map((fin, id) => (
                        <React.Fragment key={id}>
                          {' '}
                          {fin} <br />
                        </React.Fragment>
                      ))}
                    </CTableDataCell>
                  </CTableRow>
                </CTable>
              )}
              {!data.clinic.companies_house_number && (
                <CAlert
                  color="danger"
                  className="d-flex align-items-center"
                  style={{ fontSize: '0.6rem' }}
                >
                  <CIcon icon={cilInfo} className="flex-shrink-0 me-2" width={24} height={24} />
                  <div>
                    No financial data is available for this location. We are working to improve
                    this.
                  </div>
                </CAlert>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <h4 className={'mt-4 ms-1'}>
        <CTooltip
          content={
            'This section contains the treatment data that our Rare: Intelligence system automatically collects about each provider. The treatments shown below are limited to the treatments included in your subscription'
          }
        >
          <span>
            Rare:[Intelligence] <CIcon icon={cilInfo} size={'sm'} />
          </span>
        </CTooltip>
      </h4>
      <hr />
      <CRow xs={{ gutter: 1 }}>
        <CCol xs={12} sm={6} md={3}>
          <RestrictedListPanel
            title="Service Classifications"
            providedList={data.clinic.market_classification || []}
            permittedList={[]}
            toolTip={
              'Rare: Monitor classifies each provider according to the range of treatments it offers. NB -Some providers may have multiple definitions due to the range of treatments offered.'
            }
          />
        </CCol>
        <CCol xs={12} sm={6} md={3}>
          <RestrictedListPanel
            title="Treatments Offered"
            providedList={data.clinic.treatment_categories || []}
            permittedList={search.treatment_categories}
            toolTip={"This contains the treatments mentioned on the provider's website."}
          />
        </CCol>
        <CCol xs={12} sm={6} md={3}>
          <RestrictedListPanel
            title="Manufacturers Mentioned"
            providedList={data.clinic.manufacturers || []}
            permittedList={[]}
            toolTip={"This contains the manufacturers mentioned on the provider's website."}
          />
        </CCol>
        <CCol xs={12} sm={6} md={3}>
          <RestrictedListPanel
            title="Brands Mentioned"
            providedList={data.clinic.brands || []}
            permittedList={search.brands_used}
            toolTip={"This contains the brands mentioned on the provider's website."}
          />
        </CCol>
      </CRow>

      <h4 className={'ms-1 mt-4'}>
        <CTooltip
          content={
            'This section contains market information for the local authority, where the provider is based.'
          }
        >
          <span>
            Location Authority Information <CIcon icon={cilInfo} size={'sm'} />
          </span>
        </CTooltip>
      </h4>

      <hr />

      <CRow xs={{ gutter: 1 }}>
        <CCol xs={12} sm={6} md={4}>
          <CCard className={'h-100'}>
            <CCardHeader>
              <CCardTitle>
                <CTooltip
                  content={
                    'This section contains demographic information for the local authority, where the provider is based.'
                  }
                >
                  <span>
                    Demographic Information for the Local Area <CIcon icon={cilInfo} size={'sm'} />
                  </span>
                </CTooltip>
              </CCardTitle>
            </CCardHeader>
            <CCardBody>
              {data.clinic.population && (
                <CTable borderless small>
                  <CTableBody>
                    <CTableRow>
                      <CTableHeaderCell>
                        <CTooltip content={'Number of people within this area.'}>
                          <span>
                            Local Population Size <CIcon icon={cilInfo} size={'sm'} />
                          </span>
                        </CTooltip>
                      </CTableHeaderCell>
                      <CTableDataCell>
                        <NumericFormat
                          displayType="text"
                          value={data.clinic.population}
                          thousandSeparator=","
                        />
                      </CTableDataCell>
                    </CTableRow>

                    <CTableRow>
                      <CTableHeaderCell>
                        <CTooltip content={'Number of households in this area.'}>
                          <span>
                            Number of Households <CIcon icon={cilInfo} size={'sm'} />
                          </span>
                        </CTooltip>
                      </CTableHeaderCell>
                      <CTableDataCell>
                        <NumericFormat
                          displayType="text"
                          value={data.clinic.households}
                          thousandSeparator=","
                          decimalScale={0}
                        />
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableHeaderCell>
                        <CTooltip content={'Average income, per household in this area.'}>
                          <span>
                            Average Income per Household <CIcon icon={cilInfo} size={'sm'} />
                          </span>
                        </CTooltip>
                      </CTableHeaderCell>
                      <CTableDataCell>
                        <NumericFormat
                          prefix="£"
                          displayType="text"
                          value={data.clinic.average_income}
                          thousandSeparator=","
                          decimalScale={0}
                        />
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                  {/*<CTableRow>*/}
                  {/*  <CTableHeaderCell>*/}
                  {/*    <CTooltip content={"Measure of relative deprivation of the area (average) within the country."}><span>Health Deprivation Score</span></CTooltip>*/}
                  {/*  </CTableHeaderCell>*/}
                  {/*  <CTableDataCell>*/}
                  {/*    <NumericFormat displayType="text" value={0} thousandSeparator="," decimalScale={0}/>*/}
                  {/*  </CTableDataCell>*/}
                  {/*</CTableRow>*/}
                </CTable>
              )}
              {!data.clinic.population && (
                <CCardText>
                  No Demographic Data is currently available for this location. Talk to your Rare
                  Account Manager for more information
                </CCardText>
              )}
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12} sm={6} md={4}>
          <ClinicCompetition externalId={externalId} />
        </CCol>
      </CRow>

      <h4 className={'mt-4 ms-1'}>Treatments Breakdown</h4>
      <hr />
      <CRow>
        <CCol xs={12} sm={6} md={4}>
          <CompetitorTreatments externalId={externalId} />
        </CCol>
      </CRow>

      {/*<LocalPopularTreatments externalId={externalId} />*/}
    </>
  )
}

export default ClinicDetail
