import 'react-app-polyfill/stable'
import 'core-js'
import "leaflet-draw/dist/leaflet.draw.css"
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { getAuthToken } from './auth'
import {AUTH_STATE, authState} from "./graphql/policies/auth";
import {NETWORK_STATE, setNetworkState} from "./graphql/policies/network";
import {parseFeatureFlags} from "./tools/feature-flag";

const PORT = process.env.PORT || 3000

parseFeatureFlags()

// 2

// not setup envs yet, hard code replace for now:
//  uri: `http://localhost:${PORT}/graphql`
//  uri: `https://rare-ca-882ed2586c63.herokuapp.com/graphql`
const httpLink = createHttpLink({
  uri: `/graphql`
});

const errorLink = onError(({graphQLErrors, networkError}) => {
  // if(networkError) {
  //   console.log("NETWORK ERRORS", networkError)
  //   setNetworkState(NETWORK_STATE.ERROR)
  //   return false
  // } else {
  //   setNetworkState(NETWORK_STATE.OK)
  // }
  if(graphQLErrors) {
    console.error("ERRORS", graphQLErrors)
    const authErrors = graphQLErrors.filter((error) => {
      if(!error.extensions.hasOwnProperty("http")) {
        return false
      }
      return [401, 402].includes(error.extensions.http);
    })
    // We should have auth errors here
    if(authErrors.length > 0) {
      authState(AUTH_STATE.ERROR)
    }
  }
})

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

// 3
const client = new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache()
});



createRoot(document.getElementById('root')).render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
