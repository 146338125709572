export const makeRect = (bounds) => {
  const {_southWest, _northEast} = bounds
  return [
    [_northEast.lng, _southWest.lat],
    [_northEast.lng, _northEast.lat],
    [_southWest.lng, _northEast.lat],
    [_southWest.lng, _southWest.lat],
    [_northEast.lng, _southWest.lat],
  ]
}

export const rectToBounds = (rect) => {
  return [
    [rect[0][1], rect[2][0]],
    [rect[1][1],rect[0][0]]
  ]
}

