import { makeVar } from '@apollo/client'

export const AUTH_STATE = {
  WAITING: 0,
  LOGGED_IN: 1,
  LOGGED_OUT: 2,
  LOGGING_OUT: 3,
  LOGGING_IN: 4,
  ERROR: 100,
}

export const authState = makeVar(AUTH_STATE.WAITING)

export const landingPage = makeVar(undefined)

export const authError = makeVar("")

export const account = makeVar({})
