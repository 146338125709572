import React, { useEffect } from 'react'
import Levels from './Levels'
import SegmentSidebar from '../SegmentSidebar'

const MultiLevel = ({ segment, search, onChange, ...props }) => {
  const onInnerChange = (parents, segment, value) => {
    onChange(segment, value)
  }

  useEffect(() => {}, [search])

  return (
    <>
      <SegmentSidebar  {...props} {...segment}>
        <Levels
          visible={true}
          items={segment.strings}
          selected={search}
          onChange={onInnerChange}
          {...props}
        />
      </SegmentSidebar>
    </>
  )
}

export default MultiLevel
