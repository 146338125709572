import {makeVar} from "@apollo/client";

export const setSearchParams = makeVar({})
export const setSearchPage = makeVar(1)
export const setOps = makeVar({})

export const advancedSearch = makeVar(false)

export const searchResultSegments = makeVar({})

export const setTerritoryJSONCB = makeVar(0)
