import React from "react";
import ListBadge from "../AddLocationsToList/ListBadge";

const ListTags = ({lists, onRemove}) => {
  return <>
    <>
      {lists.map((list) => (
        <React.Fragment key={`list-badge-${list.id}`}><ListBadge
          {...list}
          onRemove={() => onRemove(list.id)}
        /> {' '}</React.Fragment>
      ))}
    </>
  </>
}

export default ListTags
