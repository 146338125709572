import React, { useEffect, useState } from 'react'
import { segments as coreSegments, shornSeg } from '../../../tools/set-seg-tag'
import { SegmentGroup } from '../index'
import Tag from '../../Tag'

const opMap = {
  or: 'Included',
  and: 'Required',
  not: 'Excluded',
}

const SegmentTags = ({ search, namedSegments = {}, segments = coreSegments, operation = '', allowRemove = false, onRemove = undefined, selections = {} }) => {
  const [filteredSearch, setFilteredSearch] = useState([])
  const [filteredSegments, setFilteredSegments] = useState([])

  const allowedNames = segments
    .filter((s) => s.show_tag)
    .flatMap((s) => {
      if (operation) {
        return [`${s.name}:${operation}`]
      }

      return Object.keys(opMap)
        .map((op) => `${s.name}:${op}`)

    })

  useEffect(() => {
    if (search) {
      const fSegs = []
      const filt = Object.keys(search)
        .filter((k) => allowedNames.includes(k))
        .map((k) => {
          const [seg,op] = k.split(':')
          const found = segments.find((s) => s.name === seg)
          if(found) {
            fSegs.push({...found, name: k})
          }
        })
      setFilteredSearch(filt)
      setFilteredSegments(fSegs)
    }
  }, [search, operation])

  if (filteredSearch.length > 0) {
    return <SegmentGroup name={opMap[operation] ?? ""}>
      {filteredSegments
        .map((seg) => {
          if(namedSegments[shornSeg(seg.name)]) {
            return {...seg, reduced: namedSegments[shornSeg(seg.name)]}
          }
          if (selections[shornSeg(seg.name)]) {
            return { ...seg, reduced: selections[shornSeg(seg.name)] }
          }
          return seg
        })
        .map((seg) => {


        return (<React.Fragment key={JSON.stringify(seg)}>
        {search[seg.name] && search[seg.name].map((n) => {

          let name = n
          if(seg.hasOwnProperty("reduced") && Array.isArray(seg.reduced)) {
            const found = seg.reduced.find((s) => s.value === n)
            if(found && found.name) {
              name = found.name
            }
          }
          if(typeof onRemove === "function") {
            return (
              <Tag
                key={n}
                color={'primary'}
                className={`search-segment-tag ${seg.color}`}
                onClick={() => {
                  onRemove(search, seg.name, n)
                }}
              >{name}</Tag>
            )
          } else {
            return (
              <Tag
                key={n}
                color={'primary'}
                className={`search-segment-tag ${seg.color}`}
              >{name}</Tag>
            )
          }
        })}
      </React.Fragment>)})}
    </SegmentGroup>
  } else {
    return <></>
  }
}

export default SegmentTags
