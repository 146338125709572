import { NavLink, useLocation } from 'react-router-dom'
import {CBadge, CNavItem} from '@coreui/react'
import React from 'react'
import PropTypes from 'prop-types'
import Permissions from "../../../Permissions";
import JSONPretty from "react-json-pretty";

const Nav = ({ items }) => {
  const location = useLocation()
  const navLink = (name, icon, badge) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  const navItem = (item, index) => {
    const { component, name, badge, icon, ...rest } = item
    const Component = component

    const content = (
      <li
        className={"nav-item"}
        key={index}

      >
        <NavLink {...rest} className={"nav-link ps-3 d-flex"} >
          {navLink(name, icon, badge)}
        </NavLink>

      </li>
    )
    if(item.roles) {
      return <Permissions roles={item.roles} hide key={index}>{content}</Permissions>
    } else {
      return content
    }
  }
  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item
    const Component = component
    const content = (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
      </Component>
    )

    if(item.roles) {
      return <Permissions roles={item.roles} hide key={index}>{content}</Permissions>
    } else {
      return content
    }
  }

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
    </React.Fragment>
  )
}

Nav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default Nav
