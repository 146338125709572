import React, { useEffect, useState } from 'react'
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { setAuthToken } from '../../../auth'
import { AUTH_STATE, authState } from '../../../graphql/policies/auth'

import LogoMulti from '../../../assets/brand/logo-multi.png'

const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    LogIn(email: $email, password: $password) {
      jwt
    }
  }
`


const Login = () => {
  const currentAuthState = useReactiveVar(authState)
  const [formState, setFormState] = useState({
    email: '',
    password: '',
  })

  useEffect(() => {
    if (currentAuthState === AUTH_STATE.ERROR) {
      setLoginMessage('You have been logged out by the system. Please log in again.')
    }
  }, [currentAuthState])

  const [loginMessage, setLoginMessage] = useState(null)
  const [login, { loading }] = useMutation(LOGIN_MUTATION, {
    variables: {
      email: formState.email,
      password: formState.password,
    },
    onCompleted: ({ LogIn: { jwt = '' } }) => {
      if (jwt && jwt.length > 0) {
        setAuthToken(jwt)
        authState(AUTH_STATE.LOGGED_IN)
      }
    },
    onError: (error) => {
      if (error.message === 'AUTH_FAILED') {
        setLoginMessage('Authorisation Failed: Username or password not accepted')
      } else {
        setLoginMessage('There was an error logging in. Please contact support.')
      }
    },
    notifyOnNetworkStatusChange: true,
  })

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup style={{ position: 'relative' }}>
              <CCard className="p-4">
                <CImage src={LogoMulti} height={80} align={'center'} />
                <CCardBody>
                  <CForm
                    onSubmit={(e) => {
                      e.preventDefault()
                      setLoginMessage(null)
                      login()
                    }}
                  >
                    <h2>Login</h2>
                    <p className="text-medium-emphasis">Sign In to your account</p>

                    {loginMessage && <CAlert color="danger">{loginMessage}</CAlert>}
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Email"
                        autoComplete="email"
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            email: e.target.value,
                          })
                        }
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            password: e.target.value,
                          })
                        }
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" className="px-4" type={'submit'}>
                          Login
                        </CButton>
                      </CCol>
                      {/*<CCol xs={6} className="text-right">*/}
                      {/*  <CButton color="link" className="px-0">*/}
                      {/*    Forgot password?*/}
                      {/*  </CButton>*/}
                      {/*</CCol>*/}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              {/*<CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>*/}
              {/*  <CCardBody className="text-center">*/}
              {/*    <div>*/}
              {/*      <h2>Sign up</h2>*/}
              {/*      <p>*/}
              {/*        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod*/}
              {/*        tempor incididunt ut labore et dolore magna aliqua.*/}
              {/*      </p>*/}
              {/*      <Link to="/register">*/}
              {/*        <CButton color="primary" className="mt-3" active tabIndex={-1}>*/}
              {/*          Register Now!*/}
              {/*        </CButton>*/}
              {/*      </Link>*/}
              {/*    </div>*/}
              {/*  </CCardBody>*/}
              {/*</CCard>*/}
              {loading && <div className={'h-100 w-100 position-absolute bg-opacity-50 bg-light text-center'}>
                <span className={"position-absolute top-50 start-50 translate-middle"}><CSpinner color={"primary"} style={{width: "3rem", height: "3rem"}} /></span>
              </div>}
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
