import React from "react";
import {isFeatureFlagged} from "../../tools/feature-flag";

const FeatureFlagged = ({flag = "", ...props}) => {
  if(isFeatureFlagged(flag)) {
    return <></>
  }
  return <>{props.children}</>
}


export default FeatureFlagged
