import React, { useState } from 'react'
import UserForm from './Components/UserForm'
import { useMutation, useQuery } from '@apollo/client'
import { READ_SUB_USER, UPDATE_SUB_USER } from '../../../graphql/queries/user-manage'
import {useNavigate, useParams} from 'react-router-dom'
import {CAlert, CButton, CCard, CCardBody} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilCheckCircle, cilWarning } from '@coreui/icons'
import {STATUS} from "../../../tools/const";

const Edit = () => {
  const [status, setStatus] = useState(STATUS.WAITING)
  const { userId } = useParams()
  const nav = useNavigate()

  const {
    data: { ReadSubUser: user } = { ReadSubUser: {} },
    error,
    loading: dataLoading,
  } = useQuery(READ_SUB_USER, {
    variables: {
      id: userId,
    },
  })

  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_SUB_USER, {
    onCompleted({ UpdateSubUser: { id } }) {
      if (id) {
        setStatus(STATUS.SUCCESS)
        window.scrollTo(0,0)

      }
    },
    onError(e) {
      setStatus(STATUS.FAIL)
      window.scrollTo(0,0)

    },
  })

  if (error) {
    return <CAlert color={'danger'}>{error.message}</CAlert>
  }

  return (
    <>
      {status === STATUS.SUCCESS && (
        <CAlert color={'success'} className={'d-flex align-items-center'}>
          <CIcon width={24} height={24} icon={cilCheckCircle} className={'flex-shrink-0 me-2'} />
          User updated successfully! <CButton color={"primary"} onClick={() => nav(-1)} size={"sm"} className={"mx-1"}>Return to List</CButton>
        </CAlert>
      )}
      {status === STATUS.FAIL && (
        <CAlert color={'danger'} className={'d-flex align-items-center'}>
          <CIcon width={24} height={24} icon={cilWarning} className={'flex-shrink-0 me-2'} />
          User update failed.
        </CAlert>
      )}
    <CCard>
      <CCardBody>
        <UserForm
          user={user}
          onComplete={(values) => {
            updateUser({ variables: { input: { id: userId, ...values } } })
          }}
          loading={dataLoading || updateLoading}
        />

      </CCardBody>
    </CCard>
    </>
  )
}

export default Edit
