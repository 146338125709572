import React, { useEffect, useState } from 'react'
import { SideSegment } from '../index'
import ColourChip from '../../Utility/Components/ColourChip'

const UserList = ({ segment: inSegment, onChange, children, ...props }) => {
  const [segment, setSegment] = useState({})
  const [customOpts, setCustomOpts] = useState([])
  useEffect(() => {
    setSegment({ ...inSegment, strings: [] })
    if (inSegment.strings) {
      setCustomOpts(
        [...inSegment.strings]
          .sort((a, b) => {
            if (a.name > b.name) {
              return 1
            } else if (a.name < b.name) {
              return -1
            }
            return 0
          })
          .map((el) => ({
            ...el,
            renderName: (
              <div className={'d-flex align-content-center'}>
                <ColourChip colour={el.colour} />{' '}
                <span style={{ margin: 'auto 0' }}>{el.name}</span>
              </div>
            ),
          })),
      )
    }
  }, [inSegment])

  return (
    <>
      <SideSegment
        segment={segment}
        {...props}
        customOpts={customOpts}
        ops={['or']}
        onChange={onChange}
        hideOps
      ></SideSegment>
    </>
  )
}

export default UserList
