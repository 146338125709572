import React, {useState} from "react";
import TerritoryForm from "./Components/TerritoryForm";
import {STATUS} from "../../../tools/const";
import {useApolloClient, useMutation} from "@apollo/client";
import {useNavigate} from "react-router-dom";
import {CAlert, CButton, CCard, CCardBody, CCardHeader, CCardTitle} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilCheckCircle, cilWarning} from "@coreui/icons";
import {CREATE_TERRITORY} from "../../../graphql/queries/territory";
import {setTerritoryJSONCB} from "../../../graphql/policies/search";
import {fiveDigitRand} from "../../../tools/random";

const CreateTerritory = () => {
  const [status, setStatus] = useState(STATUS.WAITING)
  const client = useApolloClient()
  const nav = useNavigate()

  const [createTerritory, {loading}] = useMutation(
    CREATE_TERRITORY, {
      onCompleted({CreateTerritory: {id}}) {
        if(id) {
          setStatus(STATUS.SUCCESS)
          setTerritoryJSONCB(fiveDigitRand())
          client.cache.evict({fieldName: 'ListTerritory'})
          client.cache.modify({
            id: "ROOT_QUERY",
            fields: {
              search(current) {
                return {
                  ...current,
                  reducedSegments: undefined
                }
              }
            }
          })
          client.cache.gc()
          window.scrollTo(0,0)
        }
      },
      onError() {
        setStatus(STATUS.FAIL)
        window.scrollTo(0,0)
      }
    }
  )

  return <>
    {status === STATUS.SUCCESS && (
      <CAlert color={'success'} className={'d-flex align-items-center'}>
        <CIcon width={24} height={24} icon={cilCheckCircle} className={'flex-shrink-0 me-2'} />
        Territory created successfully! {' '}<CButton color={"primary"} onClick={() => nav(-1)} size={"sm"} className={"mx-1"}>Return to List</CButton>
      </CAlert>
    )}
    {status === STATUS.FAIL && (
      <CAlert color={'danger'} className={'d-flex align-items-center'}>
        <CIcon width={24} height={24} icon={cilWarning} className={'flex-shrink-0 me-2'} />
        Territory creation failed.
      </CAlert>
    )}
    <CCard>
      <CCardHeader>
        <CCardTitle>Create Territory</CCardTitle>
      </CCardHeader>
      <CCardBody>
    <TerritoryForm onComplete={async (variables) => createTerritory({variables})} disableOnComplete />
      </CCardBody>
    </CCard>
  </>
}

export default CreateTerritory
