import React from "react";
import {getSubscriptionSetting} from "../../../../tools/subscription";

const LimitInformation = () => {
  return <>

    <h5 className={"mb-3"}>You may create <em>export lists</em> with up to {getSubscriptionSetting("CSV_MAX_EXPORT")} locations.</h5>
    <p>New locations will be added to an export list up to this limit, after which more locations cannot be added. Any additional locations will not be added to the list.</p>
    <p>If you wish to save and export larger export lists, or have more detailed information requirements, please contact your Rare Account Manager.</p>

  </>
}

export default LimitInformation
