import React, { useEffect, useState } from 'react'
import { CListGroup, CListGroupItem } from '@coreui/react'

const SearchList = ({ searches = [], searchId = '', onSelect = async (searchId) => void 0 }) => {
  const [selected, setSelected] = useState(searchId)
  const [active, setActive] = useState('')
  useEffect(() => {
    setActive('')
    setSelected(searchId)
  }, [searchId])

  return (
    <CListGroup>
      <>
        {searches.map((search) => {
          return (
            <CListGroupItem
              component={'button'}
              active={search.id === selected}
              onClick={() => onSelect(search.id)}
            >
              {search.name}
            </CListGroupItem>
          )
        })}
      </>
    </CListGroup>
  )
}

export default SearchList
