import React, { useState } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import { CREATE_SEARCH } from '../../../graphql/queries/search-manage'
import { CAlert, CButton, CCard, CCardBody, CCardHeader, CFormInput } from '@coreui/react'
import SearchForm from './Components/SearchForm'
import { useNavigate } from 'react-router-dom'
import defaultSearch from '../../../tools/default-search'
import { STATUS } from '../../../tools/const'

const CreateSearch = () => {
  const [status, setStatus] = useState(STATUS.WAITING)
  const client = useApolloClient()
  const [existingSearch, setExistingSearch] = useState({})
  const [createSearch, { loading: updateLoading }] = useMutation(CREATE_SEARCH, {
    onCompleted({ CreateUserSearch: { id } }) {
      if (id) {
        setStatus(STATUS.SUCCESS)
        client.cache.evict({ fieldName: 'ListUserSearch' })
        client.cache.gc()
        window.scrollTo(0, 0)
      }
    },
    onError(e) {
      setError(e.message)
      window.scrollTo(0, 0)
    },
  })
  const nav = useNavigate()

  const [title, setTitle] = useState('')
  const [search, setSearch] = useState(defaultSearch)
  const [mappedSegments, setMappedSegments] = useState([])
  const [error, setError] = useState(undefined)

  const saveSearch = async (search) => {
    if (!title) {
      setError('A name is required for your search.')
    } else {
      await createSearch({ variables: { name: title, input: search } })
      setExistingSearch(search)
    }
  }

  return (
    <>
      {status === STATUS.SUCCESS && (
        <CAlert color={'info'}>
          Search created successfully!{' '}
          <CButton color={"primary"} onClick={() => nav(-1)} size={'sm'}>
            Return to List
          </CButton>
        </CAlert>
      )}
      {error && <CAlert color={'danger'}>{error}</CAlert>}
      <CCard>
        <CCardHeader>
          <CFormInput
            type={'text'}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
              setError(undefined)
            }}
            placeholder={'Enter the name for your search'}
          />
        </CCardHeader>
        <CCardBody>
          <SearchForm
            existingSearch={existingSearch}
            mappedSegments={mappedSegments}
            onComplete={saveSearch}
          />
        </CCardBody>
      </CCard>
    </>
  )
}

export default CreateSearch
