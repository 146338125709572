import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {CButton, CCol, CRow, CSpinner} from '@coreui/react'
import Form, {useForm, useWatch} from 'rc-field-form'
import { FormField, Input } from '../../../Form'

const UserForm = ({
  user,
  onCancel = async () => void 0,
  onComplete = async (values) => void 0,
  password = false,
  loading = false,
  disableOnSubmit = false,
}) => {
  const [form] = useForm()
  const [disable, setDisable] = useState(false)

  useEffect(() => {
    if(user && Object.keys(user).length > 0) {
      setTimeout(() => {
        form.resetFields()
      }, 300)
    }
  }, [user])

  return (
    <>

      <Form
        initialValues={user}
        form={form}
        onFinish={async (values) => {
          onComplete(values)
        }}
      >
        <FormField
          name={'name'}
          label={'Name'}
          rules={[{ required: true, message: 'Name is required.' }]}
        >
          <Input disabled={disable} />
        </FormField>
        <FormField
          name={'email'}
          label={'E-Mail'}
          rules={[
            { required: true, message: 'Email is required.' },
            { type: 'email', message: 'Please enter a valid e-mail address.' },
          ]}
        >
          <Input type={'email'} disabled={disable} />
        </FormField>

        {password && (
          <FormField
            name={'password'}
            label={'Password'}
            rules={[{ required: true, message: 'Password is required.' }]}
          >
            <Input type={'text'} required disabled={disable} />
          </FormField>
        )}
        <CRow className={"mb-3"}>
          <CCol>
            <CButton color={"primary"} type={'submit'}>{loading && <CSpinner size={"sm"} />}Submit</CButton>{' '}
            <CButton color={"primary"} onClick={() => form.resetFields()}>Reset</CButton>
          </CCol>
        </CRow>
      </Form>
    </>
  )
}

UserForm.propTypes = {
  user: PropTypes.any,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  password: PropTypes.bool,
  loading: PropTypes.bool,
}

export default UserForm
