import React, { useEffect, useState } from 'react'
import { CButton, CCollapse, CListGroup, CListGroupItem } from '@coreui/react'

const EmailDisplay = ({ socialData = [] }) => {
  const [list, setList] = useState([])
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const l = socialData.filter((d) => d.type === 'email')
    setList(l)
  }, [socialData])

  if (list.length === 0) {
    return <></>
  }
  return (
    <div className={'mt-3'}>
      <CButton onClick={() => setVisible(!visible)} aria-expanded={visible} color={"secondary"} variant={"outline"}>
        Show Email Addresses
      </CButton>
      <CCollapse visible={visible}>
        <CListGroup className={'mt-2'}>
          <>
            {list.map((l) => (
              <CListGroupItem key={l.url}>{l.url}</CListGroupItem>
            ))}
          </>
        </CListGroup>
      </CCollapse>
    </div>
  )
}

export default EmailDisplay
