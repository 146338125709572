import React, { useEffect, useState } from 'react'
import { permissionsAllow } from '../../../../tools/permissions'

const ShowUserRole = ({ user }) => {
  const [isManager, setIsManager] = useState(false)
  useEffect(() => {
    if (user) {
      if (permissionsAllow(user.permissions, ['sub_search_manage', 'sub_user_manage'])) {
        setIsManager(true)
      } else {
        setIsManager(false)
      }
    }
  }, [user])
  return <>{isManager && <>Subscription Manager</>}</>
}

export default ShowUserRole
