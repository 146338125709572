import React from 'react'
import { CFormTextarea } from '@coreui/react'
import PropTypes from "prop-types";

const TextArea = ({ value = '', __FormItem = true, ...props }) => {
  return (
    <>
      <CFormTextarea defaultValue={value} {...props}></CFormTextarea>
    </>
  )
}

TextArea.propTypes = {
  value: PropTypes.string,
  __FormItem: PropTypes.bool,
}

TextArea.defaultProps = {
  __FormItem: true
}

export default TextArea
