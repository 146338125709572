

import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  Link,
  useParams
} from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import {
  cilHospital,
  cilPhone,
  cilExternalLink, cilInfo
} from '@coreui/icons'

import {
  CAlert,
  CCard,
  CCardBody,
  CCardTitle,
  CCardText,
  CCol,
  CRow,
  CSpinner, CTable, CTableRow, CTableHeaderCell, CTableDataCell, CTableBody, CCardHeader
} from '@coreui/react'

import {gql, useQuery} from "@apollo/client";


const FETCH_CLINIC = gql`
  query SingleClinic($externalId: String!) {
    clinicLocalArea(externalId: $externalId) {
      totalLocationsInArea totalLocationsInAreaInSubscription
      locations {
        externalId name distance
      }
    }
  }
`;


const ClinicCompetition = ({externalId}) => {
  const {loading, error, data} = useQuery(FETCH_CLINIC, {
    variables: {
      externalId
    }
  });

  if (loading) return (<CCard className={"h-100"}>
    <CCardHeader>
      <CCardTitle>Local Competition</CCardTitle>

    </CCardHeader>
    <CCardBody>
      <CSpinner/>
    </CCardBody>
  </CCard>);

  if (error) return <CAlert color="danger">
    <p>{error.message}</p>
  </CAlert>;

  return (
    <>
        <CCard className={"h-100"}>
          <CCardHeader>
            <CCardTitle>Local Competition</CCardTitle>

          </CCardHeader>
          <CCardBody>
            <CCardText>
              <CTable borderless small>
                <CTableBody>
                <CTableRow><CTableHeaderCell>Local Competitors (visible in your subscription)</CTableHeaderCell><CTableDataCell>{data.clinicLocalArea.totalLocationsInAreaInSubscription}</CTableDataCell></CTableRow>
                <CTableRow><CTableHeaderCell>Local Competitors (total)</CTableHeaderCell><CTableDataCell>{data.clinicLocalArea.totalLocationsInArea}</CTableDataCell></CTableRow>
                <CTableRow>&nbsp;<CTableHeaderCell></CTableHeaderCell><CTableDataCell></CTableDataCell></CTableRow>
                {data.clinicLocalArea.locations.map((val, idx) =>
                  <CTableRow key={idx}><CTableHeaderCell><Link to={"/clinic/" + val.externalId}>[{idx+1}] {val.name}</Link></CTableHeaderCell><CTableDataCell><i>about <NumericFormat displayType="text" value={val.distance} decimalScale={1} />km </i></CTableDataCell></CTableRow>
                )}
                </CTableBody>
              </CTable>
              <CAlert color="primary" className="d-flex align-items-center" style={{fontSize: "0.6rem"}}>
                <CIcon icon={cilInfo} className="flex-shrink-0 me-2" width={24} height={24}/>
                <div>We estimate local competition based our industry classification. Local competitors share one or more classifications.
                Your subscription may only show you some of these. Speak to your Rare Account Manager to learn more.
                </div>
              </CAlert>
            </CCardText>
          </CCardBody>
        </CCard>
    </>
  )
}

export default ClinicCompetition
