import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {CImage, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler} from "@coreui/react";
import ReactImg from "../../assets/brand/logo-white.png";
import SimpleBar from "simplebar-react";
import navigation from "../../_nav";
import Nav from "./Components/Nav";

const Sidebar = () => {
  const dispatch = useDispatch()
  // const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)

  return (
    <CSidebar
      position="fixed"
      // unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({type: 'set', sidebarShow: visible})
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CImage className="sidebar-brand-full" src={ReactImg} height={35}/>
      </CSidebarBrand>
      <CSidebarNav style={{height: "auto"}}>
        <SimpleBar>
          <Nav items={navigation}/>
        </SimpleBar>
      </CSidebarNav>
      {/*<CSidebarToggler*/}
      {/*  className="d-none d-lg-flex"*/}
      {/*  onClick={() => dispatch({type: 'set', sidebarUnfoldable: !unfoldable})}*/}
      {/*/>*/}
    </CSidebar>
  )
}

export default Sidebar
