// type Types = {type: string}
// type EventBus = {
//   cancel: (event: string, callback: EventListenerOrEventListenerObject) => void
//     create: (event: string, callback: EventListenerOrEventListenerObject) => void
//   emit: <T>(event: string, data: T & Types) => void
// }

const EventBus = {
  cancel: (event, callback) => document.removeEventListener(event, callback),
  create: (event, callback) => document.addEventListener(event, callback),
  emit: (event, data) => {
    document.dispatchEvent(new CustomEvent(event, {detail: data}))
  },
}


export default EventBus
