import React, {useCallback, useState} from 'react'
import {CAlert, CButton, CFormInput, CModal, CModalBody, CModalFooter, CModalHeader, CSpinner} from "@coreui/react";
import {useMutation} from "@apollo/client";
import {UPDATE_SUB_USER} from "../../../../graphql/queries/user-manage";
import {FormField, Input} from "../../../Form";

const ChangePassword = ({userId}) => {
  const [updatePass, {loading }] = useMutation(UPDATE_SUB_USER, {
    onCompleted() {
      close()
    },
    onError(e) {
      setError(e.message)
    }
  })

  const [visible, setVisible] = useState(false)
  const [pass1, setPass1] = useState("")
  const [pass2, setPass2] = useState("")
  const [error, setError] = useState("")

  const changePass = useCallback(() => {
    setError("")

    if(pass1 !== pass2) {
      setError("Passwords must match")
      return
    }

    if(pass1.length < 10 || pass2.length < 10) {
      setError("Password length must be greater than 10")
      return
    }

    updatePass({variables: {input: {id: userId, password: pass1}}})
  }, [pass1, pass2])

  const close = () => {
    setPass2("")
    setPass1("")
    setError("")
    setVisible(false)
  }

  return <>
    <CModal
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>Change Password</CModalHeader>
      <CModalBody>
        <CFormInput label={"Password"} onChange={(e) => setPass1(e.target.value)} value={pass1} required />
        <CFormInput label={"Confirm Password"} onChange={(e) => setPass2(e.target.value)} value={pass2} required />
        {error && error.length > 0 && <CAlert color={"danger"} className={"mt-3"}>{error}</CAlert>}
      </CModalBody>
      <CModalFooter className={"d-flex justify-content-between"}>
        <CButton color={"success"} disabled={loading} onClick={() => changePass()}>{loading && <><CSpinner size={"sm"} /> </> }Save</CButton>
        <CButton color={"primary"} variant={"outline"} onClick={() => close()}>Cancel</CButton>
      </CModalFooter>
    </CModal>
    <CButton onClick={() => setVisible(true)} color={"primary"}>Change Password</CButton>
  </>
}

export default ChangePassword
