import React from 'react'
import PropTypes from "prop-types";

const SegmentGroup = ({ name, children, showEmpty = false, className = "" }) => {
  if (React.Children.count(children) === 0 && !showEmpty) {
    return <></>
  }
  return (
    <>
      <div className={`segment-group${className ? ` ${className}`:""} `}>
        {name && <h6>{name}</h6>}
        {children}
      </div>
    </>
  )
}

SegmentGroup.propTypes = {
  // name: PropTypes.string.isRequired
  showEmpty: PropTypes.bool,
}

export default SegmentGroup
