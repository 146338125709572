import React, { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { AUTH_STATE, authState } from '../../graphql/policies/auth'
import {useNavigate, useSearchParams} from 'react-router-dom'

const CheckAuth = ({ children }) => {
  const currentAuthState = useReactiveVar(authState)
  const navigate = useNavigate()
  const [urlSearchParams] = useSearchParams()

  useEffect(() => {
    if (currentAuthState === AUTH_STATE.LOGGED_IN) {
      if(urlSearchParams.has("return")) {
        const ret = atob(urlSearchParams.get("return"))
        // Check validity
        if(ret.length > 1 && ret.charAt(0) === "{") {
          const parsed = JSON.parse(ret)
          navigate(parsed)
        }
      } else {
        navigate('/')
      }
    }
  }, [currentAuthState])

  return <>{children}</>
}

export default CheckAuth
