import React, {useCallback, useEffect, useRef, useState} from "react";
import {featureCollection} from "@turf/helpers";
import {GeoJSON} from "react-leaflet";

const ViewedArea = ({selected = [], regions = [], onClick = (id = '') => void 0, level = 1}) => {
  const [combinedData, setCombinedData] = useState({})
  const geoCurrent = useRef()

  const style = useCallback((feature) => {
    const {properties: {isSelected = false}} = feature
    return {
      weight: 2,
      color: "#78a",
    }
  })

  useEffect(() => {
    if(regions && regions.length > 0) {
      const features = regions.map((region) => {
        // merge features?
        const feature = region.geojson.features[0]
        let isSelected = false
        if(selected.includes(region.code) || selected.includes(region.id)) {
          isSelected = true
        }
        return {
          ...feature,
          properties: {
            territory_id: region.id,
            code: region.code,
            id: region.id, // TODO switch this to the standardised territory ID
            label: region.code,
            isSelected,
          }
        }
      })
      const f = featureCollection(features)
      setCombinedData(f)
    } else {
      setCombinedData([])
    }
  }, [selected, regions]);

  if(!combinedData || Object.keys(combinedData).length === 0) {
    return <></>
  }

  return <>
    <GeoJSON
      key={JSON.stringify(combinedData.features.map(({properties: territory_id, isSelected, code}) => ({
        territory_id, isSelected, code
      })))}
      ref={geoCurrent}
      data={combinedData}
      style={style}
      onEachFeature={(feature, layer) => {
        const {properties: {territory_id, code}} = feature
        layer.bindTooltip(code)

        if(selected.includes(territory_id)) {
          layer.bringToFront()
          layer.setStyle({
            color: "#4aa2fd",
            fillOpacity: 0.5
          })
        } else {
          layer.bringToBack()
          layer.setStyle({
            color: "#78a",
            fillOpacity: 0.3
          })
        }

        layer.on("mouseover", () => {
          layer.getTooltip()
        })
        layer.on("click", () => {
          onClick(territory_id)
        })
      }}
    />
  </>
}

export default ViewedArea
