import React, { useState } from 'react'
import {CAlert, CButton, CCard, CCardBody} from '@coreui/react'
import UserForm from './Components/UserForm'
import { useApolloClient, useMutation } from '@apollo/client'
import { CREATE_SUB_USER } from '../../../graphql/queries/user-manage'
import CIcon from '@coreui/icons-react'
import { cilCheckCircle, cilWarning } from '@coreui/icons'
import {STATUS} from "../../../tools/const";
import {useNavigate} from "react-router-dom";


const Create = () => {
  const [status, setStatus] = useState(STATUS.WAITING)
  const client = useApolloClient()
  const nav = useNavigate()

  const [createUser, {loading}] = useMutation(CREATE_SUB_USER, {
    onCompleted({CreateSubUser: {id}}) {
      if (id) {
        setStatus(STATUS.SUCCESS)
        client.cache.evict({fieldName: 'ListSubUsers'})
        client.cache.gc()
        window.scrollTo(0,0)
      }
    },
    onError() {
      setStatus(STATUS.FAIL)
      window.scrollTo(0,0)
    },
  })

  return (<>
      {status === STATUS.SUCCESS && (
        <CAlert color={'success'} className={'d-flex align-items-center'}>
          <CIcon width={24} height={24} icon={cilCheckCircle} className={'flex-shrink-0 me-2'}/>
          User created successfully! <CButton color={"primary"} onClick={() => nav(-1)} size={"sm"} className={"mx-1"}>Return to List</CButton>
        </CAlert>
      )}
      {status === STATUS.FAIL && (
        <CAlert color={'danger'} className={'d-flex align-items-center'}>
          <CIcon width={24} height={24} icon={cilWarning} className={'flex-shrink-0 me-2'}/>
          User create failed.
        </CAlert>
      )}
      <CCard>
        <CCardBody>
          <UserForm password onComplete={(input) => createUser({variables: {input}})}/>
        </CCardBody>
      </CCard>
    </>
  )
}

export default Create
