import React, { useEffect } from 'react'
import { AUTH_STATE, authState } from '../../graphql/policies/auth'
import { useApolloClient, useReactiveVar } from '@apollo/client'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from '../Loading'

const WithAuth = ({ children }) => {
  const client = useApolloClient()

  const currentAuthState = useReactiveVar(authState)
  const location = useLocation()
  const loggedIn = () => currentAuthState === AUTH_STATE.LOGGED_IN
  const navigate = useNavigate()
  useEffect(() => {
    if (!loggedIn() && currentAuthState === AUTH_STATE.LOGGING_OUT) {
      navigate('/login')
      authState(AUTH_STATE.LOGGED_OUT)
      client.clearStore()
      return
    }
    if (!loggedIn() && currentAuthState !== AUTH_STATE.WAITING) {
      if (location.pathname !== '/' || location.search.length > 0) {
        const ret = btoa(JSON.stringify(location))
        navigate({
          pathname: '/login',
          search: `return=${ret}`,
        })
      } else {
        navigate('/login')
      }
    }
  }, [currentAuthState])

  if (loggedIn()) {
    return <>{children}</>
  } else {
    return (
      <>
        <Loading />
      </>
    )
  }
}


export default WithAuth
