import React, { useEffect, useState } from 'react'
import SearchableCatalogue from '../SearchableCatalogue'
import { MARKET_CLASSIFICATION, PRODUCT } from '../../glossary-terms'
import { useQuery } from '@apollo/client'
import { MY_ACCOUNT } from '../../../../graphql/queries/my_account'
import { CCallout, CSpinner } from '@coreui/react'

const compiledData = (terms = '') => {
  return PRODUCT.map((product) => {
    const market_classification = MARKET_CLASSIFICATION.find(
      (mc) => mc.id === product.market_classification,
    )
    if (market_classification) {
      product = { ...product, market_classification: market_classification.name }
    }
    return product
  })
}

const Products = () => {
  const [data, setData] = useState([])
  const [terms, setTerms] = useState('')
  const [subscription, setSubscription] = useState(undefined)
  const [treatmentCategories, setTreatmentCategories] = useState([])
  const [marketClassifications, setMarketClassifications] = useState([])
  const [brands, setBrands] = useState([])

  const { loading, error } = useQuery(MY_ACCOUNT, {
    onCompleted({ myAccount: { subscription, user } }) {
      setSubscription(subscription)
      const { searches } = subscription
      const tc = searches.flatMap((search) => search.treatment_categories)
      const mc = searches.flatMap((search) => search.market_classification)
      const br = searches.flatMap((search) => search.brands_used)
      setTreatmentCategories(Array.from(new Set(tc)))
      setMarketClassifications(Array.from(new Set(mc)))
      setBrands(Array.from(new Set(br)))
    },
  })

  useEffect(() => {
    if (loading || !subscription) {
      setData([])
    } else {
      let finalData = compiledData(terms)
        // .map((product) => {
        //   const brand_name = product.brand_name.filter((brand) => {
        //     if (brands.length === 0) return true
        //     return brands.filter((br) => br.toLowerCase() === brand.toLowerCase()).length > 0
        //   })
        //   return { ...product, brand_name }
        // })
        // .filter((product) => {
        //   if (marketClassifications.length === 0) return true
        //   return (
        //     marketClassifications.filter(
        //       (mc) => mc.toLowerCase() === product.market_classification.toLowerCase(),
        //     ).length > 0
        //   )
        // })
        // .filter((product) => product.brand_name.length > 0)

      if (terms.length > 0) {
        finalData = finalData.filter((product) => {
          const filteredBrand = product.brand_name.filter((term) =>
            term.toLowerCase().includes(terms.toLowerCase()),
          )

          const filteredManufacturer = product.manufacturer
            .toLowerCase()
            .includes(terms.toLowerCase())

          return filteredBrand.length > 0 || filteredManufacturer
        })
      }
      setData(
        finalData.map((product) => ({
          ...product,
          brand_name: product.brand_name.join(', '),
        })),
      )
    }
  }, [terms, treatmentCategories, marketClassifications, brands, subscription])

  const columns = [
    { key: 'manufacturer', label: 'Manufacturer', _style: { width: '15rem' } },
    { key: 'treatment_category', label: 'Treatment Category', _style: { width: '15rem' } },
    {
      key: 'market_classification',
      label: 'Rare: Market Classification',
      _style: { width: '20rem' },
    },
    { key: 'brand_name', label: 'Brand Name' },
  ]

  if (loading || !subscription) {
    return (
      <>
        <CSpinner />
      </>
    )
  }

  return (
    <>
      <h4 className={'mb-2'}>Product Catalogue</h4>
      Below are a list of brands that Rare: Monitor is actively searching for, as
      part of your subscription.
      <CCallout color={'primary'} className={'bg-white'}>
        If there are any treatments or brands you would like to track then please contact your
        Account Manager.
      </CCallout>
      <SearchableCatalogue data={data} columns={columns} onSearch={setTerms} />
    </>
  )
}

export default Products
