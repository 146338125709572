import { CFormInput } from '@coreui/react'
import React, {useContext} from 'react'
import FormContext from "rc-field-form/es/FormContext";
import PropTypes from "prop-types";


const Input = ({ value = '', __FormItem= true, ...props }) => {

  return (
    <>
      <CFormInput value={value} {...props} onChange={(e) => {
        props.onChange(e)
      }}/>
    </>
  )
}

Input.propTypes = {
  value: PropTypes.string,
  __FormItem: PropTypes.bool,
}

Input.defaultProps = {
  __FormItem: true
}

export default Input
