import {
  CompanySize, FreeformSegment,
  MultiLevel,
  SideSegment,
  Territory,
  ToggleSegment,
} from '../components/Segments'
import { isFeatureFlagged } from './feature-flag'
import UserList from '../components/Segments/Components/UserList'

const isSegFlagged = (name) => {
  return !isFeatureFlagged(`segment-${name}`)
}

export const setSegTag = (search, segment, tag) => {
  const { ...newSearch } = search
  // if (segment !== 'ops') {
    if (Object.prototype.hasOwnProperty.call(newSearch, segment)) {
      const id = newSearch[segment].findIndex((t) => tag === t)
      if (id > -1) {
        const updatedSeg = [...newSearch[segment]]

        updatedSeg.splice(id, 1)

        newSearch[segment] = updatedSeg
      } else {
        newSearch[segment] = [...newSearch[segment], tag]
      }
    } else {
      newSearch[segment] = [tag]
    }
  return newSearch
}

export const replaceSegTag = (search, segment, tag) => {
  let { ...newSearch } = search
  if (!tag || tag.length === 0) {
    delete newSearch[segment]
    newSearch = {...newSearch}
  } else {
    newSearch = { ...newSearch, [segment]: [tag] }
  }
  return newSearch
}

export const setSegList = (search, params = []) => {
  let newSearch = {...search}
  params.forEach((p) => {
    if(p["replace"]) {
      newSearch = replaceSegTag(newSearch, p.name, p.value)
    } else {
      newSearch = setSegTag(newSearch, p.name, p.value)
    }
  })
  return newSearch
}

export const swapSegs = (search, segment, tag, ops) => {
  const [seg, op] = segment.split(":")
  if (op && ops.includes(op.toLowerCase())) {
    return [
      ...ops
        .filter((o) => op !== o)
        .map((o) => {
          const iSeg = `${seg}:${o}`
          if (search.hasOwnProperty(iSeg) && search[iSeg].includes(tag)) {
            return {
              name: iSeg,
              value: tag
            }
          }
          return undefined
        })
        .filter((o) => typeof o !== "undefined"),
      {name: segment, value: tag}
    ]
  }
}

export const nonSubSegs = [
  "territory",
  "new_clinics",
  "location_list",
]

const internalSegments = [
  {
    name: 'treatment_categories',
    title: 'Treatment Types',
    color: 'red',
    op: 'or',
    canReduce: false,
    component: SideSegment,
    show_tag: true,
  },
  {
    name: 'market_classification',
    title: 'Service Classifications',
    color: 'blue',
    op: 'or',
    canReduce: false,
    component: SideSegment,
    show_tag: true,
  },
  {
    name: 'catalog_data',
    title: 'Manufacturers and Brands',
    color: 'orange',
    op: 'or',
    canReduce: true,
    component: MultiLevel,
    show_tag: false,
    mapped_segments: ['manufacturers', 'brands_used']
  },
  {
    name: 'manufacturers',
    title: 'Manufacturers',
    color: 'orange',
    op: 'or',
    canReduce: true,
    component: () => <></>,
    cComponent: SideSegment,
    show_tag: true,
  },
  {
    name: 'brands_used',
    title: 'Brands Used',
    color: 'orange',
    op: 'or',
    canReduce: true,
    component: () => <></>,
    cComponent: SideSegment,
    show_tag: true,
  },
  {
    name: 'company_size',
    title: 'Company Size',
    color: 'purple',
    op: false,
    canReduce: false,
    component: CompanySize,
    show_tag: true,
  },
  {
    name: 'territory',
    title: 'Territory',
    color: 'green',
    op: false,
    canReduce: true,
    component: Territory,
    show_tag: true,
  },
  {
    name: 'location_list',
    title: "My Lists",
    show_tag: false,
    color: "purple",
    op: false,
    help: "Select locations",
    component: UserList,
  },
  {
    name: 'new_clinics',
    title: 'Show New Clinics',
    color: 'purple',
    op: false,
    canReduce: true,
    component: ToggleSegment,
    show_tag: false,
    help: 'Shows clinics added to the index in the last 90 days.',
  },
  { name: 'data_source_name', title: 'From Data Source(s)', show_tag: true, color: "purple",     op: false,
    canReduce: true,
    component: SideSegment,
  },
  {
    name: 'postal_code',
    title: 'Postcode',
    color: 'purple',
    show_tag: false,
    op: "or",
    canReduce: true,
    component: FreeformSegment,
    length: 4,
    error: 'You may only enter up to four characters in a postcode',
  },
]

export const returnSegments = (exclude = []) => {
  return internalSegments
    .filter((segment) => !exclude.includes(segment.name))
    .filter((segment) => isSegFlagged(segment.name))
}


// TODO bring this in from the API instead of hard-coding
export const segments = returnSegments()

export const accountSegments = [
  ...returnSegments(nonSubSegs)
    .filter((s) => s.name !== "catalog_data"),
]

export const shornSeg = (name) => {
  const [seg] = name.split(":")
  return seg
}
export const opTexts = {
  or: "Any",
  and: "Require",
  not: "Hide",
}
