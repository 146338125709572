import React from 'react'
import PropTypes from 'prop-types'
import {CAlert, CCard, CCardBody, CCardHeader, CFormInput, CTable} from '@coreui/react'

const SearchableCatalogue = ({ data = [], columns = [], onSearch = (text) => void 0 }) => {
  return (
    <>
      <CCard>
        <CCardHeader>
          <CFormInput
            placeholder={'Enter text to search'}
            onChange={(e) => onSearch(e.target.value)}
          />
        </CCardHeader>
        <CCardBody>
          <CTable columns={columns} items={data} />
          {data.length === 0 && (
            <>
              <CAlert color={'danger'}>
                No data matching your search is available within your subscription. Please contact
                your Rare Account Manager.
              </CAlert>
            </>
          )}
        </CCardBody>
      </CCard>
    </>
  )
}

SearchableCatalogue.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  onSearch: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired
}

export default SearchableCatalogue
