import React, { useState } from 'react'
import MessageModal from './MessageModal'
import { useEventBus } from '../../../hooks/use-event-bus'

const ModalContainer = () => {
  const [message, setMessage] = useState({})
  useEventBus('modal', ({ detail }) => {
    setMessage(detail)
  })
  return (
    <>
      <MessageModal {...message} onComplete={() => setMessage({})} />
    </>
  )
}

export default ModalContainer
