import React, { useState } from 'react'
import ListForm from '../ListForm'
import { useApolloClient, useMutation } from '@apollo/client'
import { CREATE_LOCATION_LIST } from '../../../../graphql/queries/my-lists'
import { useEventBus } from '../../../../hooks/use-event-bus'

const NewList = ({
  locations = [],
  onComplete = async (data) => void 0,
  onError = (e) => void 0,
  showModal = false,
}) => {
  const { cache } = useApolloClient()
  const { emitToast } = useEventBus('toasts')
  const [disabled, setDisabled] = useState(false)

  const [addToList] = useMutation(CREATE_LOCATION_LIST, {
    onCompleted({
      CreateUserLocationList: {
        name,
        locations: { totalCount },
      },
    }) {
      if (name) {
        emitToast({
          title: 'List Created!',
          message: (
            <>
              Your list "{name}" has been created with {totalCount} entries.
            </>
          ),
        })
        setDisabled(true)
        onComplete()
      }

      cache.evict({ fieldName: '' })
    },
    onError({ name, extraInfo, message, graphQLErrors }) {
      if (onError && typeof onError === 'function') {
        onError({ name, extraInfo, message, graphQLErrors })
      } else {
        console.log({ name, extraInfo, message, graphQLErrors })
      }
    },
  })

  return (
    <div className={'mt-3'}>
      <ListForm
        onComplete={(variables) => addToList({ variables })}
        locations={locations}
        horizontal
        disabled={disabled}
      />
    </div>
  )
}

export default NewList
