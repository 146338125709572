import React, { useState } from 'react'
import { CBadge, CButton, CPopover } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilDelete } from '@coreui/icons'
import ColourChip from '../../../Utility/Components/ColourChip'

const ListBadge = ({ name, id, colour, onRemove = async () => void 0 }) => {
  const [visible, setVisible] = useState(false)
  return (
    <React.Fragment key={id}>
      <CBadge color={'primary'} style={{ paddingBottom: '0.3rem', margin: '0.1rem' }}>
        <ColourChip
          size={'0.6rem'}
          colour={colour}
          style={{ display: 'inline-block', margin: '0 0.1rem' }}
        />{' '}
        {name}{' '}
        <CPopover
          trigger={'focus'}
          visible={visible}
          title={`Remove clinic?`}
          content={
            <>
              <p>This will remove this clinic from the "{name}" list.</p>
              <div>
                <CButton
                  color={"primary"}
                  size={'sm'}
                  onClick={() => {
                    onRemove().then(() => setVisible(false))
                  }}
                >
                  OK
                </CButton>{' '}
                <CButton color={"primary"} size={'sm'} onClick={() => setVisible(false)}>
                  Cancel
                </CButton>
              </div>
            </>
          }
        >
          <CIcon
            height={14}
            size={'custom'}
            icon={cilDelete}
            style={{ cursor: 'pointer' }}
            onClick={() => setVisible(true)}
          />
        </CPopover>
      </CBadge>
    </React.Fragment>
  )
}

export default ListBadge
