export const PERMS_STATE = {
  WAITING: 0,
  DENY: 1,
  ALLOW: 2,
}

export const permissionsAllow = (permissions = {allow: [], deny: []}, roles) => {
  const matched = permissions.allow.filter((role) => roles.includes(role.toLowerCase()))
  const denied = permissions.deny.filter((role) => roles.includes(role.toLowerCase()))
  return !(matched.length === 0 || denied.length > 0);
}
