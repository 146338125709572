import React from 'react'
import { SegmentGroup } from '../index'
import Tag from '../../Tag'
import SegmentTags from './SegmentTags'

const SegmentTagList = ({
  mappedSegments,
  search,
  filter = ['locations'],
  emptyText = 'None Selected',
  onRemove,
}) => {
  return (
    <>
      {mappedSegments
        .filter((seg) => !seg.name.includes('freeform') && !filter.includes(seg.name))
        .map((seg) => {
          const { title, color, name } = seg
          const {
            [`${name}:or`]: orTags = [],
            [`${name}:and`]: andTags = [],
            [`${name}:not`]: notTags = [],
          } = search
          const noTags = orTags.length === 0 && notTags.length === 0 && andTags.length === 0
          return (
            <div key={name}>
              <h6 className={'mt-2'}>{title}</h6>
              {orTags.length > 0 && (
                <SegmentTags
                  search={{ [`${name}:or`]: orTags }}
                  segments={mappedSegments}
                  operation={'or'}
                  onRemove={onRemove}
                />
              )}
              {andTags.length > 0 && (
                <SegmentTags
                  search={{ [`${name}:and`]: andTags }}
                  segments={mappedSegments}
                  operation={'and'}
                  onRemove={onRemove}
                />
              )}
              {notTags.length > 0 && (
                <SegmentTags
                  search={{ [`${name}:not`]: notTags }}
                  segments={mappedSegments}
                  operation={'not'}
                  onRemove={onRemove}
                />
              )}
              {noTags && (
                <SegmentGroup>
                  <Tag className={`search-segment-tag ${color ?? ''}`}>{emptyText}</Tag>
                </SegmentGroup>
              )}
            </div>
          )
        })}
    </>
  )
}

export default SegmentTagList
