import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SearchForm from './Components/SearchForm'
import { useMutation, useQuery } from '@apollo/client'
import { REACH_USER_SEARCH, UPDATE_SEARCH } from '../../../graphql/queries/search-manage'
import { CAlert, CButton, CCard, CCardBody, CCardHeader, CSpinner } from '@coreui/react'
import { nonSubSegs, returnSegments } from '../../../tools/set-seg-tag'

const EditSearch = () => {
  const [success, setSuccess] = useState(undefined)
  const [error, setError] = useState(undefined)
  const { searchId } = useParams()
  const [mappedSegments, setMappedSegments] = useState([])
  const nav = useNavigate()

  const [updateSearch] = useMutation(UPDATE_SEARCH, {
    onCompleted() {
      setSuccess('Search has been updated')
      window.scrollTo(0, 0)
    },
    onError(e) {
      setError(e.message)
      window.scrollTo(0, 0)
    },
  })

  const {
    data: { ReadUserSearch: search } = {
      ReadUserSearch: { content: {}, name: '', id: '' },
    },
    loading,
    error: apolloError,
  } = useQuery(REACH_USER_SEARCH, {
    variables: {
      id: searchId,
    },
  })

  const onComplete = async (newsearch) => {
    await updateSearch({ variables: { id: searchId, input: newsearch } })
  }

  // useEffect(() => {
  //   if (!loading) {
  //     const ms = returnSegments([...nonSubSegs, 'data_source_name', 'locations', 'catalog_data']).map((seg) => {
  //       let strings = []
  //       if (SearchSegmentData.hasOwnProperty(seg.name)) {
  //         strings = SearchSegmentData[seg.name]
  //       }
  //
  //       let {component} = seg
  //
  //       if(seg.hasOwnProperty("cComponent")) {
  //         component = seg.cComponent
  //       }
  //
  //       return {
  //         ...seg,
  //         component,
  //         strings,
  //       }
  //     })
  //     setMappedSegments(ms)
  //   }
  // }, [SearchSegmentData, loading])

  if (apolloError) {
    return <CAlert color={'danger'}>{apolloError.message}</CAlert>
  }

  return (
    <>
      {success && (
        <CAlert color={'info'}>
          {success}{' '}
          <CButton color={"primary"} onClick={() => nav(-1)} size={'sm'}>
            Return to List
          </CButton>
        </CAlert>
      )}
      {error && <CAlert color={'danger'}>{error}</CAlert>}
      <CCard>
        <CCardHeader>{search?.name ?? <CSpinner size={'sm'} />}</CCardHeader>
        <CCardBody>
          {loading && <CSpinner />}
          {/*{Object.keys(search.content).length > 0 && (*/}
            <SearchForm
              existingSearch={search.content}
              onComplete={onComplete}
            />
          {/*)}*/}
        </CCardBody>
      </CCard>
    </>
  )
}

export default EditSearch
