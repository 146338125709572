import React, { useState } from 'react'
import { CButton, CModal, CModalBody, CModalHeader } from '@coreui/react'
import { useMutation } from '@apollo/client'
import { DELETE_SUB_USER } from '../../../../graphql/queries/user-manage'

const DeleteUser = ({ userId, onComplete = async () => void 0, disabled=false }) => {
  const [visible, setVisible] = useState(false)
  const [deleteUser] = useMutation(DELETE_SUB_USER, {
    variables: {
      id: userId,
    },
    onCompleted() {
      setVisible(false)
      onComplete()
    },
  })

  return (
    <>
      <CModal size={'sm'} visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader>Confirm Delete?</CModalHeader>
        <CModalBody className={'d-flex justify-content-between'}>
          <CButton size={'sm'} color={"success"} onClick={() => deleteUser()}>
            Confirm
          </CButton>
          <CButton size={'sm'} color={"primary"} variant={"outline"} onClick={() => setVisible(false)}>
            Cancel
          </CButton>
        </CModalBody>
      </CModal>
      <CButton disabled={disabled} onClick={() => setVisible(true)} color={"danger"} className={"ms-auto"}>Delete</CButton>
    </>
  )
}

export default DeleteUser
