import {createContext, useCallback, useEffect, useReducer, useState} from "react";

const DEFAULT_VIS_TYPE = "postcode_area"
const DEFAULT_POP_TYPE = "location_count"


export const AggSearchContext = createContext({
  visType: DEFAULT_VIS_TYPE,
  popType: DEFAULT_POP_TYPE,
  search: {},
})

export const AggSearchDispatchContext = createContext((type, value) => void 0)

const defaultState = {
  visType: DEFAULT_VIS_TYPE,
  popType: DEFAULT_POP_TYPE,
  search: {}
}

const aggSearchReducer = (state = defaultState, {type, value}) => {
  let finalState = {...state}

  switch (type) {
    case "SET_VIS_TYPE": {
      finalState = {
        ...state,
        visType: value
      }
      break;
    }
    case "SET_POP_TYPE": {
      finalState = {
        ...state,
        popType: value,
        search: {},
      }
      break;
    }
    case "SET_SEARCH": {
      finalState = {
        ...state,
        search: value
      }
      break;
    }
  }

  return finalState
}

export const AggSearchProvider = ({children}) => {

  const [state, dispatchInner] = useReducer(aggSearchReducer, defaultState)

  const dispatch = (type, value) => dispatchInner({type, value})

  return <AggSearchContext.Provider value={state}>
    <AggSearchDispatchContext.Provider value={dispatch}>
      {children}
    </AggSearchDispatchContext.Provider>
  </AggSearchContext.Provider>
}
