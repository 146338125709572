import React, {useState} from "react";
import ColourChip from "../../Utility/Components/ColourChip";
import {CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CPopover} from "@coreui/react";
import {HexColorPicker} from "react-colorful";

const EditColour = ({id, colour, onChange, onComplete = () => void(0), onCancel}) => {
  const [visible, setVisible] = useState(false)

  return <>
    <CModal
      visible={visible}
      onClose={() => {
        onCancel()
        setVisible(false)
      }}
    >
      <CModalHeader>
        <CModalTitle>Edit Colour</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <HexColorPicker
          style={{margin: "0 auto"}}
          color={colour}
          onChange={onChange}
        />
      </CModalBody>
      <CModalFooter>
        <CButton
          color={"primary"}
          onClick={() => {
            onComplete()
            setVisible(false)
          }}
        >Save</CButton>
        <CButton
          color={"primary"}
          onClick={() => {
          onCancel()
          setVisible(false)
        }}>Cancel</CButton>
      </CModalFooter>
    </CModal>
    <CPopover trigger={"hover"} content={"Edit Colour"}>
      <span style={{cursor: "pointer"}} onClick={() => setVisible(true)}>
            <ColourChip colour={colour} size={"1.5rem"} />
            </span>
    </CPopover>
  </>
}

export default EditColour
