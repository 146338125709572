import React, {useEffect, useState} from 'react'
import {AppFooter, AppHeader, Sidebar} from '../components/index'
import {Outlet, useLocation} from 'react-router-dom'
import {CContainer} from '@coreui/react'
import ModalContainer from '../components/Utility/Components/ModalContainer'
import ToastContainer from '../components/Utility/Components/ToastContainer'
import GenericErrorBoundary from '../components/Utility/GenericErrorBoundary'

const DefaultLayout = () => {
  const hideOn = ['view=map','view=data-visualiser']
  const {search} = useLocation()
  const [collapse, setCollapse] = useState(false)

  useEffect(() => {
    if(search && search.length > 0 && search.includes("view=")) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  }, [search])

  return (
    <>
      <ToastContainer />
      <ModalContainer />
      <div>
        <Sidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <AppHeader collapse={collapse} />
          <div className="body flex-grow-1 px-3">
            <CContainer fluid>
              <GenericErrorBoundary>
                <Outlet />
              </GenericErrorBoundary>
            </CContainer>
          </div>
          <AppFooter collapse={collapse} />
        </div>
      </div>
    </>
  )
}

export default DefaultLayout
