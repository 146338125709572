import React from "react";
import {CToast, CToastBody, CToastHeader} from "@coreui/react";

const GeneralToast = ({title, message, onClose = () => void 0}) => (<CToast>
    <CToastHeader closeButton onClose={onClose}>
      {title}
    </CToastHeader>
    <CToastBody>
      {message}
    </CToastBody>
  </CToast>)


export default GeneralToast
