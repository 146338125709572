import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CAlert } from '@coreui/react'
import { PERMS_STATE } from '../../tools/permissions'
import { AuthContext } from '../../hooks/auth-context'

const Permissions = ({
  roles = [],
  deniedRoles = [],
  overlay,
  loading,
  children,
  override = false,
  hide = false,
  loadingOverlay = <></>,
}) => {
  const {
    userRolesPermit,
    user: { permissions },
  } = useContext(AuthContext)

  const [can, setCan] = useState(PERMS_STATE.WAITING)

  useEffect(() => {
    if (loading) {
      setCan(PERMS_STATE.WAITING)
    } else {
      if (!userRolesPermit(roles) && !override) {
        setCan(PERMS_STATE.DENY)
      } else {
        setCan(PERMS_STATE.ALLOW)
      }
    }
  }, [roles, permissions, loading])

  if (loading || PERMS_STATE.WAITING) {
    return loadingOverlay
  }

  if (can === PERMS_STATE.ALLOW) {
    return <>{children}</>
  } else {
    if (hide) {
      return <></>
    }

    if (overlay) {
      return <>{overlay}</>
    } else {
      return <CAlert color={'warning'}>You don't have permission to perform this function.</CAlert>
    }
  }
}

Permissions.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  overlay: PropTypes.element,
  loadingOverlay: PropTypes.element,
  loading: PropTypes.bool,
  override: PropTypes.bool,
  hide: PropTypes.bool,
}

export default Permissions
