import React, { useEffect, useState } from 'react'
import SegmentSidebar from './SegmentSidebar'
import { CListGroup } from '@coreui/react'
import SegmentListItem from './SegmentListItem'
import useSearch from '../../../hooks/use-search'

const SideSegment = ({
  segment,
  segmentSearch = [],
  search = {},
  onChange,
  onSelectAll = undefined,
  onClear,
  ops = [],
  showOps = true,
  customOpts = undefined,
  showFilter = true,
  ...props
}) => {
  const [strings, setStrings] = useState([])
  const { removeParam } = useSearch()
  useEffect(() => {
    setStrings(segment.strings ?? [])
  }, [segment])
  return (
    <>
      <SegmentSidebar {...props} {...segment} ops={ops} onClear={onClear}>
        <div style={{ height: '100%', overflow: 'auto' }}>
          {props.children}
          <CListGroup>
            <>
              {customOpts &&
                [...customOpts].map((item, id) => (
                  <React.Fragment key={`${item.value}-${id}`}>
                    <SegmentListItem
                      item={{ ...item, segment: segment.name }}
                      value={item.value}
                      selected={search}
                      onChange={onChange}
                      ops={ops}
                    />
                  </React.Fragment>
                ))}
            </>
            <>
              {[...strings]
                .sort((a, b) => {
                  if (['number', 'string'].includes(typeof a)) {
                    if (a > b) {
                      return 1
                    } else if (b > a) {
                      return -1
                    }
                  }
                  return 0
                })
                .map((s, id) => (
                  <React.Fragment key={`${s}-${id}`}>
                    <SegmentListItem
                      item={{ name: s, segment: segment.name }}
                      selected={search}
                      onChange={onChange}
                      ops={ops}
                    />
                  </React.Fragment>
                ))}
            </>
          </CListGroup>
        </div>
      </SegmentSidebar>
    </>
  )
}

export default SideSegment
