import React from "react";
import {CBadge, CCloseButton} from "@coreui/react";

const Tag = ({children, className, onClick, ...props}) => {
  return <CBadge {...props} className={`search-tag ${className}`}>
    {children}
      {onClick && <CCloseButton className={"tag-closer"} white onClick={() => onClick()} />}
  </CBadge>
}

Tag.propTypes = {
  ...CBadge.propTypes,
}

export default Tag
