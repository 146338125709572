import { gql } from '@apollo/client'

export const MY_ACCOUNT = gql`
  query MyAccount {
    myAccount {
      subscription {
        searches
        name
        seats
        settings
      }
      user {
        id
        permissions {
          allow deny
        }
        name
      }
    }

  }
`

export const CHECK_AUTH = gql`
  query CheckAuth {
    CheckAuth
  }
`

export const USER_PERMISSIONS = gql`
  query AccountPermissions {
    myAccount {
      subscription {
        searches
      }

      user {
        id
        permissions {
          allow deny
        }
      }
    }
  }
`

