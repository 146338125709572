import React, { useCallback, useState } from 'react'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CSpinner } from '@coreui/react'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  ASSIGN_SUB_USER_SEARCH,
  READ_SEARCH_USERS,
} from '../../../../graphql/queries/search-manage'
import { CSmartTable } from '@coreui/react-pro'

const AssignUsers = ({ id, onComplete = () => void 0 }) => {
  const [visible, setVisible] = useState(false)
  const [items, setItems] = useState([])
  const [removes, setRemoves] = useState([])
  const [subUsers, setSubUsers] = useState([])

  const saveUsers = async () => {
    await assignUsers({ variables: { subUserId: items, removeId: removes } })
  }

  const [loadUserData, { data, loading, refetch }] = useLazyQuery(READ_SEARCH_USERS, {
    fetchPolicy: 'no-cache',
    variables: { searchId: id },
    onCompleted({ ListSearchUsers, ListSubUsers }) {
      if (ListSearchUsers && ListSubUsers) {
        const { entries: seu = [] } = ListSearchUsers
        const { entries: sbu = [] } = ListSubUsers

        setSubUsers(
          sbu.map((user) => {
            const found = seu.find((u) => u.id === user.id)
            if (found) {
              return {
                ...user,
                _selected: true,
              }
            } else {
              return user
            }
          }),
        )
      }
    },
  })

  const [assignUsers, { loading: updateLoading }] = useMutation(ASSIGN_SUB_USER_SEARCH, {
    variables: {
      searchId: id,
    },
    onCompleted() {
      onComplete()
      setVisible(false)
    },
  })

  const updateItems = useCallback(
    (its) => {
      const newIds = its.map((i) => i.id)
      const newRemoves = items.filter((i) => !newIds.includes(i))

      const mergedRemoves = Array.from(new Set([...newRemoves, ...removes]))
      const diffMergeRem = mergedRemoves.filter((i) => !newIds.includes(i))

      setRemoves(diffMergeRem)

      setItems(newIds)
      // const filteredRems = Array.from(new Set([...rems, ...removes]))
      // setRemoves(filteredRems)
    },
    [items, removes],
  )

  const columns = [
    {
      key: 'name',
    },
  ]

  return (
    <>
      <CModal visible={visible} onClose={() => setVisible(false)} portal>
        <CModalHeader>
          <>{loading || (updateLoading && <CSpinner size={'sm'} />)}Assign Users</>
        </CModalHeader>
        <CModalBody>
          <CSmartTable
            key={`${id}-${JSON.stringify(subUsers)}`}
            selectable
            items={subUsers}
            columns={columns}
            onSelectedItemsChange={(its) => updateItems(its)}
          />
        </CModalBody>
        <CModalFooter>
          <CButton color={"primary"} onClick={() => saveUsers()}>Save</CButton>
        </CModalFooter>
      </CModal>
      <CButton
        color={"primary"}
        onClick={() => {
          loadUserData()
          setVisible(true)
        }}
      >
        Assign Users
      </CButton>
    </>
  )
}

export default AssignUsers
