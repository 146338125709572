import L from 'leaflet'
import {isFeatureFlagged} from "../../../tools/feature-flag";

function colourIcon(colours = [], count = 0) {
  let color = ''
  if (!isFeatureFlagged('segment-location_list') && colours.length > 0) {
    color = `--list-col: ${colours[0]}`
  }

  return L.divIcon({
    html: `<span style="${color};"><span></span></span>`,
    className: 'monitor-map-icon single',
  })
}


export default colourIcon
