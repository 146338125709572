import React from "react";
import {CBadge, CTooltip} from "@coreui/react";

const BetaBadge = ({style = {}, ...props}) => {
  return <>
    <CTooltip content={"This is a beta feature. Its form and function may change."}>
      <CBadge size={'sm'} color={'success'} style={{cursor: "pointer", ...style}}>
        Beta
      </CBadge>
    </CTooltip>
  </>
}

export default BetaBadge
