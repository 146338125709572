import React, {useState} from "react";
import CIcon from "@coreui/icons-react";
import {cilTrash} from "@coreui/icons";
import {CButton, CPopover} from "@coreui/react";
import {useMutation} from "@apollo/client";
import {REMOVE_LOCATIONS_FROM_LIST} from "../../../graphql/queries/my-lists";

const RemoveLocationFromList = ({id, location_id, onComplete = () => void 0}) => {
  const [visible, setVisible] = useState(false)
  const [remove] = useMutation(REMOVE_LOCATIONS_FROM_LIST, {
    variables: {
      locations: [location_id],
      id,
    },
    onCompleted() {
      onComplete()
      setVisible(false)
    }
  })

  const inner = <>
    <p>You can always add it again from a search later.</p>
    <div className={"d-flex justify-content-between"}>
      <CButton size={"sm"} color={"primary"} onClick={() => remove()}>OK</CButton>
      <CButton size={"sm"} color={"primary"} onClick={() => setVisible(false)}>Cancel</CButton>
    </div>
  </>
  return <>
    <CPopover visible={visible} onHide={() => setVisible(false)} title={"Remove location from list?"}
              content={inner}><span></span></CPopover>
    <CButton color={"primary"} onClick={() => setVisible(!visible)}><CIcon icon={cilTrash}/></CButton>
  </>
}

export default RemoveLocationFromList
