const defaultSearch = {
  treatment_categories: [],
  market_classifications: [],
  brands_used: [],
  postal_code: [],
  data_source_name: [],
  locations: [],
  company_size: []
}

export default defaultSearch
