import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { CButton, CSpinner } from '@coreui/react'
import { saveAs } from 'file-saver'
import { useLazyQuery } from '@apollo/client'
import { VIEW_LOCATION_LIST } from '../../../../graphql/queries/my-lists'
import MessageModal from "../../../Utility/Components/MessageModal";

const Export = ({ id, length }) => {
  const [getList, { loading }] = useLazyQuery(VIEW_LOCATION_LIST, {
    variables: {
      id,
      page: 1,
      limit: length,
    },
  })

  const [message, setMessage] = useState({})


  const save = async () => {
    const { data } = await getList()

    if(!data) {
      setMessage({
        title: "No list to be exported",
        message: "You cannot export an empty list.",
        onOk: () => setMessage({})
      })
      return
    }

    const {UserLocationList} = data

    if (UserLocationList && UserLocationList.locations.entries.length > 0) {
      const filename = `${UserLocationList.name}.csv`

      const { locations: {entries = []} } = UserLocationList
      const csv = `Clinic Name,Phone,Address,Postcode,Contact,E-Mail,Social Media\n${entries
        .map((l) =>
          [
            `"${l.name}"`,
            `"${l.phone_number}"`,
            `"${Object.keys(l.address)
              .filter((f) => f !== '__typename')
              .filter((f) => f !== "postal_code")
              .map((f) => l.address[f])
              .filter((f) => !!f)
              .join(', ')}"`,
            `"${Object.keys(l.address)
              .filter((f) => f === "postal_code")
              .map((f) => l.address[f])}"`,
            `${Array.from(new Set(l.contacts.map((c) => `"${c.name}"`))).join(', ')}`,
            `"${Array.from(new Set(l.social_data.filter((sd) => sd.type === "email").map((sd) => `${sd.url}`))).join("; ")}"`,
            `"${Array.from(new Set(l.social_data.filter((sd) => sd.type !== "email").map((sd) => `${sd.url}`))).join("; ")}"`,
          ].join(','),
        )
        .join('\n')}`

      try {
        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'})

        saveAs(blob, filename)
      } catch (e) {
        console.error(e)
      }
    } else {
      alert("Couldn't download file. Check the console.")
    }
  }

  return (
    <>
      <CButton color={"primary"} size={'sm'} onClick={() => save()}>
        {loading && <CSpinner size={'sm'} />}
        Export List
      </CButton>
      <MessageModal {...message} />
    </>
  )
}

Export.propTypes = {
  id: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
  length: PropTypes.number,
}

export default Export
