import React from 'react'

import {CAlert, CCard, CCardBody, CCardHeader, CCardText, CCardTitle, CTooltip} from '@coreui/react'
import PropTypes from 'prop-types'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons'

const RestrictedListPanel = ({ permittedList = [], providedList, title, toolTip }) => {
  let visibleList = []

  let infoPanel = <></>

  if (permittedList.length === 0) {
    visibleList = providedList
    infoPanel = <></>
  } else {
    visibleList = providedList.filter((val) => permittedList.includes(val))
    if (providedList.length > 0 && providedList.length !== visibleList.length) {
      infoPanel = (
        <>
          <CAlert color="warning" style={{ fontSize: '0.6rem' }}>
            We have discovered {providedList.length - visibleList.length} more items items outside
            of your subscription. Contact your Rare Account manager if you'd like to learn more
          </CAlert>
        </>
      )
    }
  }

  return (
    <>
      <CCard className={"h-100"}>
        <CCardHeader>
          <CCardTitle>
            {toolTip ? (
              <>
                <CTooltip content={toolTip}>
                  <span>
                    {title} <CIcon icon={cilInfo} size={'sm'} />
                  </span>
                </CTooltip>
              </>
            ) : (
              <>{title}</>
            )}
          </CCardTitle>
        </CCardHeader>
        <CCardBody>

          <CCardText>{visibleList.join(', ')}</CCardText>
          {infoPanel}

          {visibleList.length === 0 && <CCardText>No information available</CCardText>}
        </CCardBody>
      </CCard>
    </>
  )
}

RestrictedListPanel.propTypes = {
  permittedList: PropTypes.arrayOf(PropTypes.string),
  providedList: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  toolTip: PropTypes.string,
}

export default RestrictedListPanel
