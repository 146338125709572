import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { UPDATE_LOCATION_LIST, VIEW_LOCATION_LIST } from '../../graphql/queries/my-lists'
import { CCard, CCardBody, CCardHeader, CCardTitle } from '@coreui/react'
import EditName from './Components/EditName'
import LocationList from './Components/LocationList'
import Export from './Components/Export'
import EditColour from './Components/EditColour'
import Permissions from "../Permissions";

const ViewList = () => {
  const { listId } = useParams()
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [curColour, setCurColour] = useState('#fff')
  const { cache } = useApolloClient()
  // const [totalCount, setTotalCount] = useState(0)
  const limit = 10
  const {
    data: {
      UserLocationList: {
        name,
        colour,
        locations: { entries, totalCount },
      },
    } = {
      UserLocationList: { name: '', colour: '#fff', locations: { entries: [], totalCount: 0 } },
    },
    refetch,
  } = useQuery(VIEW_LOCATION_LIST, {
    variables: {
      id: listId,
      page,
      limit,
    },
  })

  useEffect(() => {
    setCurColour(colour)
  }, [colour])

  useEffect(() => {
    let p = 1
    if (totalCount > 0) {
      p = Math.ceil(totalCount / limit)
      if (p < 1) {
        p = 1
      }
    }
    setPages(p)
  }, [totalCount, limit])

  const [updateList, { loading }] = useMutation(UPDATE_LOCATION_LIST, {
    onCompleted() {
      refetch()
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'SearchSegmentData' })
    },
  })

  return (
    <>
      <CCard>
        <CCardHeader>
          <CCardTitle style={{ display: 'flex', alignContent: 'center' }}>
            <EditColour
              id={listId}
              colour={curColour}
              onChange={setCurColour}
              onComplete={() => {
                updateList({ variables: { colour: curColour, id: listId } })
              }}
              onCancel={() => setCurColour(colour)}
            />

            <span style={{ margin: 'auto 0.3rem' }}>
              <EditName
                name={name}
                onComplete={async (name) => updateList({ variables: { name, id: listId } })}
              />
            </span>
          </CCardTitle>
        </CCardHeader>
        <CCardBody>
          <Permissions hide roles={["location_list_export"]}><Export id={listId} length={totalCount} /></Permissions>
          <LocationList
            locations={entries}
            listId={listId}
            page={page}
            setPage={setPage}
            pages={pages}
            onChange={refetch}
          />
        </CCardBody>
      </CCard>
    </>
  )
}

export default ViewList
